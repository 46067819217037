import { FC, useState, useEffect } from "react";
import { ethers, ContractTransaction, BigNumber } from "ethers";
import {
  Multicall,
  ContractCallResults,
  ContractCallContext,
} from "ethereum-multicall";

import StrategyData from "./AerodromeStrategyData";
import { ADDRESSES, ABIs } from "../../ContractInfo";
import { AssetInfo, Route, ZapInData, ZapOutData } from "./AeroInterfaces";
import { generateZapInData } from "./AeroCompoundHelper";

type AerodromeDataProps = {
  currentAccount: string;
  currentProvider: ethers.providers.Web3Provider | undefined;
  sickleAddress: string;
  setIsUpdatingData: (isUpdatingData: boolean) => void;
  isDeployingSickle: boolean;
  sendTransaction: (
    transaction: ContractTransaction
  ) => Promise<boolean>;
  selectedNetwork: string;
};

const AerodromeData: FC<AerodromeDataProps> = ({
  currentAccount,
  currentProvider,
  sickleAddress,
  setIsUpdatingData,
  isDeployingSickle,
  sendTransaction,
  selectedNetwork
}) => {
  // state

  const [txCounter, setTXcounter] = useState<number>(0);

  const [, setEthBalance] = useState<BigNumber>(BigNumber.from(0));
  const [AEROprice, setAEROprice] = useState<number>(0);
  const [assetList, setAssetList] = useState<AssetInfo[]>([
  ]);

  // functions

  useEffect(() => {
    // fetch balances & contract data

    setIsUpdatingData(true);    

    const fetchBalancesAndContractData = async () => {
      if (currentProvider) {
        let fetchedEthBalance = await currentProvider.getBalance(currentAccount);
        setEthBalance(fetchedEthBalance);

        const newAssetList: any[] | ((prevState: AssetInfo[]) => AssetInfo[]) = [];

        // fetching number of pools and pool info

        const PoolFactory = new ethers.Contract(
          ADDRESSES.Base.AERODROME?.PoolFactory ?? "",
          ABIs.AERODROME.PoolFactory,
          currentProvider?.getSigner()
        );

        const allPoolsLength = await PoolFactory.allPoolsLength();

        const SugarV2 = new ethers.Contract(
          ADDRESSES.Base.AERODROME?.SugarV2 ?? "",
          ABIs.AERODROME.SugarV2,
          currentProvider?.getSigner()
        );
        const poolInfoList = await SugarV2.all(allPoolsLength, 0, "0x0000000000000000000000000000000000000000");

        type PoolInfo = {
          account_balance: BigNumber,
          account_earned: BigNumber,
          account_staked: BigNumber,
          bribe: string;
          claimable0: BigNumber,
          claimable1: BigNumber,
          decimals: number,
          emissions: BigNumber,
          emissions_token: string,
          factory: string,
          fee: string,
          gauge: string,
          gauge_alive: boolean,
          gauge_total_supply: BigNumber,
          lp: string,
          pool_fee: BigNumber,
          reserve0: BigNumber,
          reserve1: BigNumber,
          stable: boolean,
          symbol: string,
          token0: string,
          token0_fees: BigNumber,
          token1: string,
          token1_fees: BigNumber,
          total_supply: BigNumber,
        }

        type TokenInfo = {
          token_address: string,
          symbol: string,
          decimals: number,
          account_balance: BigNumber,
          listed: boolean
        }

        // fetch token list and filter tokens listed as incentivized
        const tokenList = await SugarV2.tokens(2000, 0, "0x0000000000000000000000000000000000000000");
        const tokenListSelection = tokenList.filter((el: TokenInfo) => el.listed === true);

        // fetching token prices expressed in USD
        const VeloOracle = new ethers.Contract(
          ADDRESSES.Base.AERODROME?.VeloOracle ?? "",
          ABIs.AERODROME.VeloOracle,
          currentProvider?.getSigner()
        );

          // copied from Aerodrome UI
        const rates = await VeloOracle.getManyRatesWithConnectors(
          tokenListSelection.length,
          tokenListSelection.map((el: TokenInfo) => el.token_address.toLowerCase())
          .concat("0x833589fcd6edb6e08f4c7c32d4f71b54bda02913,0x940181a94A35A4569E4529A3CDfB74e38FD98631,0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb,0x4621b7a9c75199271f773ebd9a499dbd165c3191,0x4200000000000000000000000000000000000006,0xb79dd08ea68a908a97220c76d19a6aa9cbde4376,0xf7a0dd3317535ec4f4d29adf9d620b3d8d5d5069,0xcfa3ef56d303ae4faaba0592388f19d7c3399fb4".split(",").map(t=>t.toLowerCase()))
          .concat("0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA")
          );

        type TokenPriceList = {
          [key: string]: string;
        }
        const tokenPriceList: TokenPriceList = {};
        rates.forEach((el: BigNumber, i: number) => tokenPriceList[tokenListSelection[i].token_address] = rates[i].toString());

        const poolsWithEmissions = poolInfoList.filter(
          (el: PoolInfo) => 
          el.emissions.gt(0) 
          && 
          (
            tokenListSelection.find((elem: TokenInfo) => elem.token_address === el.token0)?.length > 0
            ||
            tokenListSelection.find((elem: TokenInfo) => elem.token_address === el.token1)?.length > 0
          )
        );

        poolsWithEmissions.forEach((el: PoolInfo) => {
          newAssetList.push(
            {
              LPname: el.symbol,
              token0: "",
              token1: "",
              token0address: el.token0,
              token1address: el.token1,
              isStable: el.stable,
              poolFee: el.pool_fee,
              token0decimals: BigNumber.from(0),
              token1decimals: BigNumber.from(0),
              token0price: 0,
              token1price: 0,
              poolBalance0: el.reserve0,
              poolBalance1: el.reserve1,
              staked: BigNumber.from(0),
              totalSupplyLP: el.total_supply,
              token0balance: BigNumber.from(0),
              token1balance: BigNumber.from(0),
              rewardRate: el.emissions,
              pendingRewardBalance: BigNumber.from(0),
              gaugeAddress: el.gauge,
              poolAddress: el.lp,
              token0approval: BigNumber.from(0),
              token1approval: BigNumber.from(0),
              TVL: 0,
              APR: 0,
              APY: 0
            }
          );
        });

        const multicall = new Multicall({
          ethersProvider: currentProvider,
          tryAggregate: true,
        });

        // fetching USDC and AERO price
        const contractCallContext1: ContractCallContext[] = [
          {
            reference: "ChainlinkUSDCpriceFeed",
            contractAddress: ADDRESSES.Base.CHAINLINK?.USDCpriceFeed ?? "",
            abi: ABIs.CHAINLINK.EACAggregatorProxy,
            calls: [
              {
                reference: "USDCprice",
                methodName: "latestRoundData",
                methodParameters: [],
              },
            ],
          },
          {
            reference: "Router",
            contractAddress: ADDRESSES.Base.AERODROME?.Router ?? "",
            abi: ABIs.AERODROME.Router,
            calls: [
              {
                reference: "AEROUSDCprice",
                methodName: "getAmountsOut",
                methodParameters: [
                  "1000000000000000000",
                  [
                    {
                      from: ADDRESSES.Base.TOKENS?.AERO,
                      to: ADDRESSES.Base.TOKENS?.USDbC,
                      stable: false,
                      factory: ADDRESSES.Base.AERODROME?.PoolFactory
                    }
                  ]
                ],
              },
            ],
          },
        ];

        const results1: ContractCallResults = await multicall.call(
          contractCallContext1
        );

        const {
          results: {
            ChainlinkUSDCpriceFeed: {
              callsReturnContext: {
                0: {
                  returnValues: {
                    1: { hex: USDC_PRICE },
                  },
                },
              },
            },
          },
          results: {
            Router: {
              callsReturnContext: {
                0: {
                  returnValues: {
                    1: {hex: AEROUSDCprice},
                  },
                },
              },
            },
          },
        } = results1;

        let fetchedAEROprice = parseInt((BigNumber.from(AEROUSDCprice)).mul(USDC_PRICE).toString()) / 1e8 / 1e6;
        setAEROprice(fetchedAEROprice);

        // fetching rest of pool info and current account balances
        const contractCallContext2: ContractCallContext[] = [];
        poolsWithEmissions.forEach((el: PoolInfo) => {
          contractCallContext2.push(
            {
              reference: `${el.lp}-token0`,
              contractAddress: el.token0,
              abi: ABIs.AERODROME.Pool,
              calls: [
                {
                  reference: "symbol",
                  methodName: "symbol",
                  methodParameters: [],
                },
                {
                  reference: "decimals",
                  methodName: "decimals",
                  methodParameters: [],
                },
                {
                  reference: "balance",
                  methodName: "balanceOf",
                  methodParameters: [currentAccount],
                },
              ],
            },
            {
              reference: `${el.lp}-token1`,
              contractAddress: el.token1,
              abi: ABIs.AERODROME.Pool,
              calls: [
                {
                  reference: "symbol",
                  methodName: "symbol",
                  methodParameters: [],
                },
                {
                  reference: "decimals",
                  methodName: "decimals",
                  methodParameters: [],
                },
                {
                  reference: "balance",
                  methodName: "balanceOf",
                  methodParameters: [currentAccount],
                },
              ],
            },
          );
        });

        const results2: ContractCallResults = await multicall.call(
          contractCallContext2
        );

        poolsWithEmissions.forEach((el : PoolInfo, index: number) => {
          newAssetList[index].token0 = results2.results[`${el.lp}-token0`].callsReturnContext[0].returnValues[0];
          newAssetList[index].token0decimals = BigNumber.from(results2.results[`${el.lp}-token0`].callsReturnContext[1].returnValues[0]);
          newAssetList[index].token0balance = el.token0 === ADDRESSES.Base.TOKENS?.WETH ? fetchedEthBalance : BigNumber.from(results2.results[`${el.lp}-token0`].callsReturnContext[2].returnValues[0]);
          newAssetList[index].token1 = results2.results[`${el.lp}-token1`].callsReturnContext[0].returnValues[0];
          newAssetList[index].token1decimals = BigNumber.from(results2.results[`${el.lp}-token1`].callsReturnContext[1].returnValues[0]);
          newAssetList[index].token1balance = el.token1 === ADDRESSES.Base.TOKENS?.WETH ? fetchedEthBalance : BigNumber.from(results2.results[`${el.lp}-token1`].callsReturnContext[2].returnValues[0]);
          newAssetList[index].token0price = parseInt(tokenPriceList[newAssetList[index].token0address]) / 1e18;
          newAssetList[index].token1price = parseInt(tokenPriceList[newAssetList[index].token1address]) / 1e18;
          newAssetList[index].TVL = newAssetList[index].token0price * parseInt(newAssetList[index].poolBalance0.toString()) / (10 ** newAssetList[index].token0decimals.toNumber()) + newAssetList[index].token1price * parseInt(newAssetList[index].poolBalance1.toString()) / (10 ** newAssetList[index].token1decimals.toNumber());;
          newAssetList[index].APR = parseInt(newAssetList[index].rewardRate.toString()) * 3600 * 24 * 365 * fetchedAEROprice * 100 / 1e18 / newAssetList[index].TVL;
          newAssetList[index].APY = (((1 + (newAssetList[index].APR / 100) / 365) ** 365) - 1) * 100;
        });

        if (sickleAddress.length > 0) {
          
          // fetching sickle-related pool info
          const contractCallContext3: ContractCallContext[] = [];
          poolsWithEmissions.forEach((el: PoolInfo) => {
            contractCallContext3.push(
              {
                reference: el.gauge,
                contractAddress: el.gauge,
                abi: ABIs.AERODROME.Gauge,
                calls: [
                  {
                    reference: "staked",
                    methodName: "balanceOf",
                    methodParameters: [sickleAddress],
                  },
                  {
                    reference: "pendingRewardBalance",
                    methodName: "earned",
                    methodParameters: [sickleAddress],
                  },
                ],
              },
              {
                reference: `${el.lp}-token0`,
                contractAddress: el.token0,
                abi: ABIs.AERODROME.Pool,
                calls: [
                  {
                    reference: "approval",
                    methodName: "allowance",
                    methodParameters: [currentAccount, sickleAddress],
                  },
                ],
              },
              {
                reference: `${el.lp}-token1`,
                contractAddress: el.token1,
                abi: ABIs.AERODROME.Pool,
                calls: [
                  {
                    reference: "approval",
                    methodName: "allowance",
                    methodParameters: [currentAccount, sickleAddress],
                  },
                ],
              },
            )
          });

          const results3: ContractCallResults = await multicall.call(
            contractCallContext3
          );

          poolsWithEmissions.forEach((el : PoolInfo, index: number) => {
            newAssetList[index].staked = BigNumber.from(results3.results[`${el.gauge}`].callsReturnContext[0].returnValues[0]);
            newAssetList[index].pendingRewardBalance = BigNumber.from(results3.results[`${el.gauge}`].callsReturnContext[1].returnValues[0]);
            newAssetList[index].token0approval = el.token0 === ADDRESSES.Base.TOKENS?.WETH ? BigNumber.from(0) : BigNumber.from(results3.results[`${el.lp}-token0`].callsReturnContext[0].returnValues[0]);
            newAssetList[index].token1approval = el.token1 === ADDRESSES.Base.TOKENS?.WETH ? BigNumber.from(0) : BigNumber.from(results3.results[`${el.lp}-token1`].callsReturnContext[0].returnValues[0]);
          });
        }

        // sort pool by descending APR
        const temp1 = newAssetList.filter(x => x.staked.gt(0) || x.pendingRewardBalance.gt(0)).sort((a, b) => b.APR - a.APR)
        const temp2 = newAssetList.filter(x => !x.staked.gt(0) || !x.pendingRewardBalance.gt(0)).sort((a, b) => b.APR - a.APR)
        console.log("temp1", temp1)
        const data = temp1.concat(temp2)
        console.log("Data", data)

        setAssetList(data);

      }
      setIsUpdatingData(false);
    }

    try {
      fetchBalancesAndContractData();
    } catch {
      window.alert("Failed to fetch balances and contract data");
      setIsUpdatingData(false);
    }

    return () => {
      setEthBalance(BigNumber.from(0));
      setAEROprice(0);
      setAssetList([]);
    }

  }, [txCounter]);
  
  const harvestAEROrewards = async (targetGauge: string, poolAddress: string, exitToken: string) => {
    if (sickleAddress.length > 0 && !isDeployingSickle) {
      // claim AERO reward tokens and sweep to user wallet or zap into exit token
      let AerodromeStrategyABI = new ethers.utils.Interface(
        JSON.stringify(ABIs.STRATEGIES.AerodromeStrategy)
      );
      const AerodromeStrategy = new ethers.Contract(
        ADDRESSES.Base.STRATEGIES?.AerodromeStrategy ?? "",
        AerodromeStrategyABI,
        currentProvider?.getSigner()
      );

      let zapOutData: ZapOutData = {
        router: ADDRESSES.Base.AERODROME?.Router ?? "",
        lpToken: poolAddress,
        lpAmountIn: BigNumber.from(0),
        minAmountOutA: 0,
        minAmountOutB: 0,
        routeToOther: [],
        otherMinAmountOut: 0,
        routeToIntermediate: [],
        intermediateMinAmountOut: BigNumber.from(0),
        isNativeTokenOut: false,
        isStablePool: false
      };

      if(exitToken === '') { // claim and sweep without zap
        zapOutData.router = ADDRESSES.Base.AERODROME?.Router ?? "";        
      } else {
        zapOutData.router = ADDRESSES.Base.AERODROME?.Router ?? "";        
        const routeToIntermediate: Route = {
          from: ADDRESSES.Base.TOKENS?.AERO ?? "",
          to: exitToken,
          stable: false,
          factory: ADDRESSES.Base.AERODROME?.PoolFactory ?? ""
        };
        zapOutData.routeToIntermediate[0] = routeToIntermediate;
        if(exitToken === ADDRESSES.Base.TOKENS?.WETH) zapOutData.isNativeTokenOut = true;
      }

      let gasEstimate;
      try { 
          gasEstimate = await AerodromeStrategy.estimateGas.aerodrome_harvest(
          sickleAddress,
          targetGauge,
          zapOutData
        );
      } catch {
        window.alert("Could not estimate gas for transaction");
        return;
      }

      console.log("Transaction")
      const value = await sendTransaction(
        AerodromeStrategy.aerodrome_harvest(
          sickleAddress,
          targetGauge,
          zapOutData,
          {
            gasLimit: gasEstimate.mul(110).div(100), // additional 10% margin on gas estimate
          }
        )
      );
      console.log(value)
      setTXcounter(txCounter+1);

    } else {
      window.alert("Sickle is not deployed !");
    }
  } 

  const compoundAEROrewards = async (targetGauge: string, poolAddress: string, pendingRewardBalance: BigNumber, isStable: boolean, token0: string, token1: string) => {
    if (sickleAddress.length > 0 && !isDeployingSickle) {      
      // compound AERO reward tokens into the same pool

      const zapInData =  await generateZapInData(currentProvider, poolAddress, pendingRewardBalance, isStable, token0, token1)

      if(zapInData.error){
        window.alert(zapInData.errorMessage)
        return
      }

      let AerodromeStrategyABI = new ethers.utils.Interface(
        JSON.stringify(ABIs.STRATEGIES.AerodromeStrategy)
      );
      const AerodromeStrategy = new ethers.Contract(
        ADDRESSES.Base.STRATEGIES?.AerodromeStrategy ?? "",
        AerodromeStrategyABI,
        currentProvider?.getSigner()
      );

      let gasEstimate;
      try {
        gasEstimate = await AerodromeStrategy.estimateGas.aerodrome_compound(
          sickleAddress,
          targetGauge,
          zapInData.zapInData
        );
      } catch {
        window.alert("Could not estimate gas for transaction");
        return;
      }

      await sendTransaction(
        AerodromeStrategy.aerodrome_compound(
          sickleAddress,
          targetGauge,
          zapInData.zapInData,
          {
            gasLimit: gasEstimate.mul(110).div(100), // additional 10% margin on gas estimate
          }
        )
      );
      setTXcounter(txCounter+1);

    } else {
      window.alert("Sickle is not deployed !");
    }
  } 

  const zapInAndStake = async (entryToken: string, pairedToken: string, amountIn: BigNumber, poolAddress: string, isStable: boolean, approvalNeeded: boolean, gaugeAddress: string) => {
    if (sickleAddress.length > 0 && !isDeployingSickle) {
      // zap into the pool and stake
      let AerodromeStrategyABI = new ethers.utils.Interface(
        JSON.stringify(ABIs.STRATEGIES.AerodromeStrategy)
      );
      const AerodromeStrategy = new ethers.Contract(
        ADDRESSES.Base.STRATEGIES?.AerodromeStrategy ?? "",
        AerodromeStrategyABI,
        currentProvider?.getSigner()
      );

      let res;
      // manage token approval if needed
      if(approvalNeeded) {
        const targetTokenContract = new ethers.Contract(
          entryToken,
          ABIs.DFORCE.iToken,
          currentProvider?.getSigner()
        );
        res = await sendTransaction(
          targetTokenContract.approve(
            sickleAddress,
            amountIn
          )
        ); // full balance is used to create a position
      }

      if(res || !approvalNeeded) {

        let zapInData: ZapInData = {
          router: ADDRESSES.Base.AERODROME?.Router ?? "",
          tokenIn: entryToken === ADDRESSES.Base.TOKENS?.WETH ? "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE": entryToken,
          amountIn : amountIn,
          routeToIntermediate: [],
          intermediateMinAmountOut: amountIn,
          routeToOther: [],
          otherMinAmountOut: 0,
          lpToken: poolAddress,
          lpTokenMinAmountOut : 0,
          isStablePool: isStable
        }

        const routeToOther: Route = {
          from: entryToken,
          to: pairedToken,
          stable: isStable,
          factory: ADDRESSES.Base.AERODROME?.PoolFactory ?? ""
        }
        zapInData.routeToOther[0] = routeToOther;
        
        let gasEstimate;
        try {
          gasEstimate = await AerodromeStrategy.estimateGas.aerodrome_deposit(
            sickleAddress,
            gaugeAddress,
            zapInData,
            {value: entryToken === ADDRESSES.Base.TOKENS?.WETH ? amountIn : BigNumber.from(0)}
          );
        } catch {
          window.alert("Could not estimate gas for transaction");
          return;
        }
        
        
          
          await sendTransaction(
            AerodromeStrategy.aerodrome_deposit(
              sickleAddress,
              gaugeAddress,
              zapInData,
              {
                value: entryToken === ADDRESSES.Base.TOKENS?.WETH ? amountIn : BigNumber.from(0),
                gasLimit: gasEstimate.mul(110).div(100), // additional 10% margin on gas estimate
              }
            )
          );
            setTXcounter(txCounter+1);

      }

    } else {
      window.alert("Sickle is not deployed !");
    }
  } 
  const unstakeAndZapOut = async (poolAddress: string, amountOut: BigNumber, isStablePool: boolean, exitToken: string, pairedToken: string, gaugeAddress: string) => {
    if (sickleAddress.length > 0 && !isDeployingSickle) {
      // unstake and zap out into exit token
      let AerodromeStrategyABI = new ethers.utils.Interface(
        JSON.stringify(ABIs.STRATEGIES.AerodromeStrategy)
      );
      const AerodromeStrategy = new ethers.Contract(
        ADDRESSES.Base.STRATEGIES?.AerodromeStrategy ?? "",
        AerodromeStrategyABI,
        currentProvider?.getSigner()
      );

      let zapOutData: ZapOutData = {
        router: ADDRESSES.Base.AERODROME?.Router ?? "",
        lpToken: poolAddress,
        lpAmountIn: amountOut,
        minAmountOutA: 0,
        minAmountOutB: 0,
        routeToOther: [],
        otherMinAmountOut: 0,
        routeToIntermediate: [],
        intermediateMinAmountOut: BigNumber.from(0),
        isNativeTokenOut: exitToken === ADDRESSES.Base.TOKENS?.WETH ? true : false,
        isStablePool: isStablePool
      }

      const routeToOther: Route = {
        from: pairedToken,
        to: exitToken,
        stable: isStablePool,
        factory: ADDRESSES.Base.AERODROME?.PoolFactory ?? ""
      }
      zapOutData.routeToOther[0] = routeToOther;

      let gasEstimate;
      try {
        gasEstimate = await AerodromeStrategy.estimateGas.aerodrome_withdraw(
          sickleAddress,
          gaugeAddress,
          zapOutData
        );
      } catch {
        window.alert("Could not estimate gas for transaction");
        return;
      }

      if (await sendTransaction(
        AerodromeStrategy.aerodrome_withdraw(
          sickleAddress,
          gaugeAddress,
          zapOutData,
          {
            gasLimit: gasEstimate.mul(110).div(100), // additional 10% margin on gas estimate
          }
        )
      ))
        setTXcounter(txCounter+1);

    } else {
      window.alert("Sickle is not deployed !");
    }
  } 

  return (
    <div>
      <div style={{ marginTop: "20px" }}>
        ----------------------------------------------------------------------------
      </div>
      <div style={{ marginTop: "20px", fontWeight: "bold" }}>
        Aerodrome on {selectedNetwork}
      </div>
      <div style={{ marginTop: "20px" }}>
        AERO token price : {AEROprice.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})}
      </div>
      {[...assetList].map( 
        ({
          LPname,
          token0,
          token1,
          token0address,
          token1address,
          isStable,
          poolFee,
          token0decimals,
          token1decimals,
          poolBalance0,
          poolBalance1,
          staked,
          totalSupplyLP,
          token0balance,
          token1balance,
          pendingRewardBalance,
          gaugeAddress,
          poolAddress,
          token0approval,
          token1approval,
          TVL,
          APR,
          rewardRate,
          APY
        }, index) => (TVL > 1000 && APR > 5 ?
          <StrategyData
          key={index}
          LPname={LPname}
          token0={token0}
          token1={token1}
          token0address={token0address}
          token1address={token1address}
          isStable={isStable}
          poolFee={poolFee}
          token0decimals={token0decimals}
          token1decimals={token1decimals}
          poolBalance0={poolBalance0}
          poolBalance1={poolBalance1}
          AEROprice={AEROprice}
          staked={staked}
          totalSupplyLP={totalSupplyLP}
          token0balance={token0balance}
          token1balance={token1balance}
          pendingRewardBalance={pendingRewardBalance}
          gaugeAddress={gaugeAddress}
          poolAddress={poolAddress}
          token0approval={token0approval}
          token1approval={token1approval}
          TVL={TVL}
          APR={APR}
          rewardRate={rewardRate}
          APY={APY}
          harvestAEROrewards={harvestAEROrewards}
          compoundAEROrewards={compoundAEROrewards}
          zapInAndStake={zapInAndStake}
          unstakeAndZapOut={unstakeAndZapOut}
          /> : null
        )
      )}
    </div>
  );
};

export default AerodromeData;
