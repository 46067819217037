import {FC, useState} from 'react';
import { ethers, BigNumber } from 'ethers';

type StrategyDataProps = {
  LPname: string;
  token0: string;
  token1: string;
  token0address: string;
  token1address: string;
  isStable: boolean;
  poolFee: BigNumber;
  token0decimals: BigNumber;
  token1decimals: BigNumber;
  poolBalance0: BigNumber;
  poolBalance1: BigNumber;
  AEROprice: number;
  staked: BigNumber;
  totalSupplyLP: BigNumber;
  token0balance: BigNumber;
  token1balance: BigNumber;
  pendingRewardBalance: BigNumber;
  gaugeAddress: string;
  poolAddress: string;
  token0approval: BigNumber;
  token1approval: BigNumber;
  TVL: number;
  APR: number;
  rewardRate: BigNumber;
  APY: number;
  harvestAEROrewards: (targetGauge: string, poolAddress: string, exitToken: string) => void;
  compoundAEROrewards: (targetGauge: string, poolAddress: string, pendingRewardBalance: BigNumber, isStable: boolean, token0: string, token1: string) => void;
  zapInAndStake: (entryToken: string, pairedToken: string, amountIn: BigNumber, poolAddress: string, isStable: boolean, approvalNeeded: boolean, gaugeAddress: string) => void;
  unstakeAndZapOut: (poolAddress: string, amountOut: BigNumber, isStablePool: boolean, exitToken: string, pairedToken: string, gaugeAddress: string) => void;
}

const StrategyData: FC<StrategyDataProps> = ({
  LPname,
  token0,
  token1,
  token0address,
  token1address,
  isStable,
  poolFee,
  token0decimals,
  token1decimals,
  poolBalance0,
  poolBalance1,
  AEROprice,
  staked,
  totalSupplyLP,
  token0balance,
  token1balance,
  pendingRewardBalance,
  gaugeAddress,
  poolAddress,
  token0approval,
  token1approval,
  TVL,
  APR,
  rewardRate,
  APY,
  harvestAEROrewards,
  compoundAEROrewards,
  zapInAndStake,
  unstakeAndZapOut
}) => {

  const [inputZap0Value, setInputZap0Value] = useState<string>("");
  const [inputZap1Value, setInputZap1Value] = useState<string>("");

  const handleInputValue = (inputType: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9]*[.,]?[0-9]*$/;
    if (e.target.value === '') {
      if (inputType === 'zap0') setInputZap0Value("");
      if (inputType === 'zap1') setInputZap1Value("");
    } else if (regex.test(e.target.value)) {
      if (inputType === 'zap0') setInputZap0Value(e.target.value);
      if (inputType === 'zap1') setInputZap1Value(e.target.value);
    }
  }

  const onMaxClick = (inputType: string) => {
    if (inputType === 'zap0') setInputZap0Value(ethers.utils.formatUnits(token0balance, token0decimals))
    if (inputType === 'zap1') setInputZap1Value(ethers.utils.formatUnits(token1balance, token1decimals))
}

const onHarvestAEROrewards = async(exitToken: string) => {
  if(pendingRewardBalance.eq(0)) {
    window.alert("There are no pending rewards to claim");
  } else {
    await harvestAEROrewards(gaugeAddress, poolAddress, exitToken);
  }
}

const onCompoundAEROrewards = async() => {
  if(pendingRewardBalance.eq(0)) {
    window.alert("There are no pending rewards to compound");
  } else {
    await compoundAEROrewards(gaugeAddress, poolAddress, pendingRewardBalance, isStable, token0address, token1address);
  }
}

const onZapInAndStake = async(entryTokenSymbol: string, entryTokenAddress: string, pairedTokenAddress: string, amountInString: string, decimals: BigNumber, approval: BigNumber, balance: BigNumber) => {

  if(amountInString === "") {
    window.alert("Input amount cannot be empty");
    return;
  }

  // convert string input into bigNumber value
  let amountInParam = ethers.utils.parseUnits(amountInString, decimals) //BigNumber.from(`${parseFloat(amountInString) * 10 ** decimals.toNumber()}`);
console.log(balance.toString(), amountInParam.toString())
  if(amountInParam.gt(balance)) {
    window.alert(`Input amount cannot be greater than your ${entryTokenSymbol === 'WETH' ? 'ETH' : entryTokenSymbol} balance`);
    return;
  }

  // approval needed if the entry token is not ETH and amountIn is greater than current allowance
  let approvalNeeded = entryTokenSymbol !== 'WETH' && amountInParam.gt(approval) ? true : false;

  await zapInAndStake(entryTokenAddress, pairedTokenAddress, amountInParam, poolAddress, isStable, approvalNeeded, gaugeAddress);

}

const onUnstakeAndZapOut = async(exitTokenAddress: string, pairedTokenAddress: string) => {

  if(staked.eq(0)) {
    window.alert(`You have no ${token0}/${token1} LP tokens to unstake`);
    return;
  }

  await unstakeAndZapOut(poolAddress, staked, isStable, exitTokenAddress, pairedTokenAddress, gaugeAddress);

}

const feeDisplay = `${(parseInt(poolFee.toString()) / 100).toFixed(2)}%`;
const poolBalance0number = token0decimals.eq(18) ? parseFloat(ethers.utils.formatEther(poolBalance0)) : parseInt(poolBalance0.toString()) / (10 ** token0decimals.toNumber());
const poolBalance1number = token1decimals.eq(18) ? parseFloat(ethers.utils.formatEther(poolBalance1)) : parseInt(poolBalance1.toString()) / (10 ** token1decimals.toNumber());
const poolBalance0display = poolBalance0number.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2});
const poolBalance1display = poolBalance1number.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2});
const TVLdisplay = TVL.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2});
const stakedDisplay = ethers.utils.formatEther(staked);
const stakedValueNumber = parseInt(staked.toString()) * TVL / parseInt(totalSupplyLP.toString());
const stakedValueDisplay = stakedValueNumber.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2});
const token0balanceDisplay = (parseInt(token0balance.toString()) / (10 ** token0decimals.toNumber())).toFixed(2);
const token1balanceDisplay = (parseInt(token1balance.toString()) / (10 ** token1decimals.toNumber())).toFixed(2);
const pendingRewardBalanceDisplay = parseFloat(ethers.utils.formatEther(pendingRewardBalance)).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2});
const pendingRewardValueDisplay = (parseFloat(ethers.utils.formatEther(pendingRewardBalance)) * AEROprice).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2});
const APRdisplay = APR.toFixed(2);
const yearlyEarnings = (stakedValueNumber * APR / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2});
const monthlyEarnings = (stakedValueNumber * APR / 100 / 12).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2});
const dailyEarnings = (stakedValueNumber * APR / 100 / 365).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2});
const AEROrewardPerWeekDisplay = (parseInt(rewardRate.toString()) * 3600 * 24 * 7 / 1e18).toFixed(2);
const AEROrewardPerWeekInUSDDisplay = (parseFloat(AEROrewardPerWeekDisplay) * AEROprice).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2});
const APYdisplay = APY.toFixed(2);

    return (
        <div style={{marginTop: "20px"}}>
          <div style={{display: "flex", flexDirection: "row"}}>
            <button >{LPname} {isStable ? "(stable)" : "(volatile)"}</button>
            <div style={{marginLeft: "5px"}}>Fee: {feeDisplay}</div>
          </div>
          <div>Pool Balance: {poolBalance0display} {token0} / {poolBalance1display} {token1}</div>
          <div>TVL: {TVLdisplay}</div>
          <div><b>Current APR: {APRdisplay}% | {AEROrewardPerWeekDisplay} AERO per week ({AEROrewardPerWeekInUSDDisplay})</b></div>
          <div><b>Current APY: {APYdisplay}% (Daily Compound)</b></div>
          {staked.gt(0) && 
            <div style={{marginTop: "5px"}}>
                <div>Staked via Sickle: {stakedDisplay} LP tokens ({stakedValueDisplay})</div>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div>Estimated earnings: Day {dailyEarnings} Month {monthlyEarnings} Year {yearlyEarnings}</div>
                </div>
            </div>}
            {staked.gt(0) ? 
            <>
              <div style ={{lineHeight: "21px"}}>
              <div style={{display: "flex",maxWidth:"50%", minWidth:"550px", justifyContent:"space-between", flexDirection: "row", marginTop: "10px", height:"21px", verticalAlign:"center"}}>
                  <button style={{textDecoration: "underline", margin: "0"}} onClick={() => onZapInAndStake(token0, token0address, token1address, inputZap0Value, token0decimals, token0approval, token0balance)}>
                      Zap in {token0 === 'WETH' ? 'ETH' : token0} and stake (current balance: {token0balanceDisplay})
                  </button>
                  <div>
                    <input type="text" placeholder="0" pattern="^[0-9]*[.,]?[0-9]*$" value={inputZap0Value} onChange={(e) => handleInputValue("zap0", e)} maxLength={11}></input>
                    <button style={{textDecoration: "underline", margin: "0", paddingLeft: "10px"}} onClick={() => onMaxClick("zap0")}>MAX</button>
                  </div>
              </div>
              <div style={{display: "flex", flexDirection: "row", maxWidth:"50%", minWidth:"550px", justifyContent:"space-between", height:"21px", verticalAlign:"center"}}>
                  <button style={{textDecoration: "underline", margin: "0"}} onClick={() => onZapInAndStake(token1, token1address, token0address, inputZap1Value, token1decimals, token1approval, token1balance)}>
                      Zap in {token1 === 'WETH' ? 'ETH' : token1} and stake (current balance: {token1balanceDisplay})
                  </button>
                  <div>
                    <input type="text" placeholder="0" pattern="^[0-9]*[.,]?[0-9]*$" value={inputZap1Value} onChange={(e) => handleInputValue("zap1", e)} maxLength={11}></input>
                    <button style={{textDecoration: "underline", margin:"0", paddingLeft: "10px"}} onClick={() => onMaxClick("zap1")}>MAX</button>
                  </div>
              </div>
              <div style={{display: "flex", flexDirection: "row"}}>
                  <button style={{textDecoration: "underline",  margin: "0", height:"21px"}} onClick={() => onUnstakeAndZapOut(token0address, token1address)}>
                      Unstake and zap out into {token0 === 'WETH' ? 'ETH' : token0}
                  </button>
              </div>
              <div style={{display: "flex", flexDirection: "row"}}>
                  <button style={{textDecoration: "underline",  margin: "0", height:"21px"}} onClick={() => onUnstakeAndZapOut(token1address, token0address)}>
                      Unstake and zap out into {token1 === 'WETH' ? 'ETH' : token1}
                  </button>
              </div>
              <div>
                  <button style={{ margin: "0"}} onClick={() => onHarvestAEROrewards('')}>Claim {pendingRewardBalanceDisplay} AERO tokens ({pendingRewardValueDisplay}) </button>
              </div>
              <div>
                  <button style={{ margin: "0"}} onClick={() => onHarvestAEROrewards(token0address)}>Claim {pendingRewardBalanceDisplay} AERO tokens ({pendingRewardValueDisplay}) and zap into {token0 === 'WETH' ? 'ETH' : token0}</button>
              </div>
              <div>
                  <button style={{ margin: "0"}} onClick={() => onHarvestAEROrewards(token1address)}>Claim {pendingRewardBalanceDisplay} AERO tokens ({pendingRewardValueDisplay}) and zap into {token1 === 'WETH' ? 'ETH' : token1}</button>
              </div>
              <div>
                  <button style={{ margin: "0"}} onClick={() => onCompoundAEROrewards()}>Compound {pendingRewardBalanceDisplay} AERO tokens ({pendingRewardValueDisplay})</button>
              </div>
            </div>
            </>
            : pendingRewardBalance.gt(0) 
            ? <>
            <div style ={{lineHeight: "21px"}}>
              <div style={{display: "flex",maxWidth:"50%", minWidth:"550px", justifyContent:"space-between", flexDirection: "row", marginTop: "10px", height:"21px", verticalAlign:"center"}}>
                  <button style={{textDecoration: "underline", margin: "0"}} onClick={() => onZapInAndStake(token0, token0address, token1address, inputZap0Value, token0decimals, token0approval, token0balance)}>
                      Zap in {token0 === 'WETH' ? 'ETH' : token0} and stake (current balance: {token0balanceDisplay})
                  </button>
                  <div>
                    <input type="text" placeholder="0" pattern="^[0-9]*[.,]?[0-9]*$" value={inputZap0Value} onChange={(e) => handleInputValue("zap0", e)} maxLength={11}></input>
                    <button style={{textDecoration: "underline", margin: "0", paddingLeft: "10px"}} onClick={() => onMaxClick("zap0")}>MAX</button>
                  </div>
              </div>
              <div style={{display: "flex", flexDirection: "row", maxWidth:"50%", minWidth:"550px", justifyContent:"space-between", height:"21px", verticalAlign:"center"}}>
                  <button style={{textDecoration: "underline", margin: "0"}} onClick={() => onZapInAndStake(token1, token1address, token0address, inputZap1Value, token1decimals, token1approval, token1balance)}>
                      Zap in {token1 === 'WETH' ? 'ETH' : token1} and stake (current balance: {token1balanceDisplay})
                  </button>
                  <div>
                    <input type="text" placeholder="0" pattern="^[0-9]*[.,]?[0-9]*$" value={inputZap1Value} onChange={(e) => handleInputValue("zap1", e)} maxLength={11}></input>
                    <button style={{textDecoration: "underline", margin:"0", paddingLeft: "10px"}} onClick={() => onMaxClick("zap1")}>MAX</button>
                  </div>
              </div>
              <div>
                  <button style={{ margin: "0"}} onClick={() => onHarvestAEROrewards('')}>Claim {pendingRewardBalanceDisplay} AERO tokens ({pendingRewardValueDisplay}) </button>
              </div>
              <div>
                  <button style={{ margin: "0"}} onClick={() => onHarvestAEROrewards(token0address)}>Claim {pendingRewardBalanceDisplay} AERO tokens ({pendingRewardValueDisplay}) and zap into {token0 === 'WETH' ? 'ETH' : token0}</button>
              </div>
              <div>
                  <button style={{ margin: "0"}} onClick={() => onHarvestAEROrewards(token1address)}>Claim {pendingRewardBalanceDisplay} AERO tokens ({pendingRewardValueDisplay}) and zap into {token1 === 'WETH' ? 'ETH' : token1}</button>
              </div>
              <div>
                  <button style={{ margin: "0"}} onClick={() => onCompoundAEROrewards()}>Compound {pendingRewardBalanceDisplay} AERO tokens ({pendingRewardValueDisplay})</button>
              </div>
            </div>
            </>
            : <>
            <div style ={{lineHeight: "21px"}}>
              <div style={{display: "flex",maxWidth:"50%", minWidth:"550px", justifyContent:"space-between", flexDirection: "row", marginTop: "10px", height:"21px", verticalAlign:"center"}}>
                  <button style={{textDecoration: "underline", margin: "0"}} onClick={() => onZapInAndStake(token0, token0address, token1address, inputZap0Value, token0decimals, token0approval, token0balance)}>
                      Zap in {token0 === 'WETH' ? 'ETH' : token0} and stake (current balance: {token0balanceDisplay})
                  </button>
                  <div>
                    <input type="text" placeholder="0" pattern="^[0-9]*[.,]?[0-9]*$" value={inputZap0Value} onChange={(e) => handleInputValue("zap0", e)} maxLength={11}></input>
                    <button style={{textDecoration: "underline", margin: "0", paddingLeft: "10px"}} onClick={() => onMaxClick("zap0")}>MAX</button>
                  </div>
              </div>
              <div style={{display: "flex", flexDirection: "row", maxWidth:"50%", minWidth:"550px", justifyContent:"space-between", height:"21px", verticalAlign:"center"}}>
                  <button style={{textDecoration: "underline", margin: "0"}} onClick={() => onZapInAndStake(token1, token1address, token0address, inputZap1Value, token1decimals, token1approval, token1balance)}>
                      Zap in {token1 === 'WETH' ? 'ETH' : token1} and stake (current balance: {token1balanceDisplay})
                  </button>
                  <div>
                    <input type="text" placeholder="0" pattern="^[0-9]*[.,]?[0-9]*$" value={inputZap1Value} onChange={(e) => handleInputValue("zap1", e)} maxLength={11}></input>
                    <button style={{textDecoration: "underline", margin:"0", paddingLeft: "10px"}} onClick={() => onMaxClick("zap1")}>MAX</button>
                  </div>
              </div>
              </div>
              </>
            }
      </div>
    );
};

export default StrategyData;