import {FC, useState} from 'react';
import { ethers, BigNumber } from 'ethers';

type CompState = {
    block: number,
    index: BigNumber,
}

type StrategyDataProps ={
    name: string,
    price: number,
    marketSize: string,
    liquidity: string,
    LTV: string,
    supplied: BigNumber,
    borrowed: BigNumber,
    supplyRate: string,
    borrowRate: string,
    supplyDFrewards: string,
    borrowDFrewards: string,
    currentBalance: BigNumber,
    flashloanFee: number,
    pendingDFrewards: BigNumber,
    supplyState: CompState,
    borrowState: CompState,
    supplySpeed: BigNumber,
    borrowSpeed: BigNumber,
    totalSupply: BigNumber,
    totalBorrows: BigNumber,
    supplierIndex: BigNumber,
    borrowerIndex: BigNumber,
    referenceBlockNumber: number,
    DFprice: number,
    borrowIndex: BigNumber,
    claimDFrewards: () => void,
    compoundDFrewards: (iToken: string, supplied: BigNumber, borrowed: BigNumber) => void,
    foldTokenAmountWithFlashloan: (iToken: string, amount: BigNumber) => void,
    closeTokenAmountWithFlashloan: (iToken: string, amount: BigNumber, isExiting: boolean) => void,
    selectedNetwork: string;
}

const StrategyData: FC<StrategyDataProps> = ({
    name,
    price,
    marketSize,
    liquidity,
    LTV,
    supplied,
    borrowed,
    supplyRate,
    borrowRate,
    supplyDFrewards,
    borrowDFrewards,
    currentBalance,
    flashloanFee,
    pendingDFrewards,
    supplyState,
    borrowState,
    supplySpeed,
    borrowSpeed,
    totalSupply,
    totalBorrows,
    supplierIndex,
    borrowerIndex,
    referenceBlockNumber,
    DFprice,
    borrowIndex,
    claimDFrewards,
    compoundDFrewards,
    foldTokenAmountWithFlashloan,
    closeTokenAmountWithFlashloan,
    selectedNetwork,
}) => {
    let totalPendingDFRewards = pendingDFrewards;

    const [inputFoldValue, setInputFoldValue] = useState<string>("");
    const [inputCloseValue, setInputCloseValue] = useState<string>("");
    const [estimatedAPYvalue, setEstimatedAPYvalue] = useState<number>(0);

    const onMaxClick = (maxLeverage: number) => {
        maxLeverage.toFixed(2);
        setInputFoldValue(maxLeverage.toString())
    }
  
    const handleInputValue = (inputType: string, e: React.ChangeEvent<HTMLInputElement>) => {
      const regex = /^[0-9]*[.,]?[0-9]*$/;
      if (e.target.value === '') {
        if (inputType === 'fold') {
            setInputFoldValue("");
            setEstimatedAPYvalue(0);
        }
        if (inputType === 'close') setInputCloseValue("");
      } else if (regex.test(e.target.value)) {
        if (inputType === 'fold') {
            setInputFoldValue(e.target.value);
            let convertedBalance = name === 'DAI' ? parseFloat(ethers.utils.formatEther(currentBalance)) : ((currentBalance.div(1e4)).toNumber() / 100);
            let newEstimatedAPY : number;
            if(parseFloat(e.target.value) > convertedBalance) {
                newEstimatedAPY = ((parseFloat(e.target.value) * (parseFloat(supplyRate) + parseFloat(supplyDFrewards))) / convertedBalance) - (((parseFloat(e.target.value) - convertedBalance) * (parseFloat(borrowRate) - parseFloat(borrowDFrewards))) / convertedBalance);
            } else {
                newEstimatedAPY = 0;
            }
            setEstimatedAPYvalue(newEstimatedAPY);
        }
        if (inputType === 'close') setInputCloseValue(e.target.value);
      }
    }

    const onClaimDFrewards = async() => {
        if(totalPendingDFRewards.eq(0)) {
            window.alert("There are no pending rewards to claim");
        } else {
            await claimDFrewards();
        }
    }

    const onCompoundDFrewards = async() => {
        if(totalPendingDFRewards.eq(0)) {
            window.alert("There are no pending rewards to claim");
        } else {
            await compoundDFrewards(name, supplied, borrowed);
        }
    }

    const onFoldTokenAmountWithFlashloan = async() => {

        if(inputFoldValue === "") {
            window.alert("Input amount cannot be empty");
            return;
        }

        let convertedInput;
        if(name === 'DAI') {
            convertedInput = ethers.utils.parseEther(inputFoldValue);
        } else {
            convertedInput = BigNumber.from(parseFloat(inputFoldValue) * 1e6); // six decimals for USDC and USDT tokens
        }

        let flashloanMultiplier = ((100 + flashloanFee) / (100 + flashloanFee - parseFloat(LTV)));

        if((convertedInput.mul(1e15)).gte(currentBalance.mul(Math.round(1e15 * flashloanMultiplier)))) {
            window.alert(`Input amount exceeds your current ${name} balance multiplied by the max leverage level`);
        } else if (convertedInput.lte(currentBalance)) {
            window.alert(`Input amount cannot be lower or equal than your ${name} balance`);
        } else {
            await foldTokenAmountWithFlashloan(name, convertedInput);
        }
    }

    const onCloseTokenAmountWithFlashloan = async(isExiting: boolean) => {

        if((inputCloseValue === "" || parseFloat(inputCloseValue) === 0) && !isExiting) {
            window.alert('Input amount cannot be zero');
            return;
        }

        if(isExiting) {
            if(borrowed.eq(0)) {
                window.alert(`Sickle does not have outstanding ${name} debt to repay`);
                return;
            } else {
                await closeTokenAmountWithFlashloan(name, borrowed.add(borrowed.div(1000)), true); // must add 0.1% due to rounding errors in CToken conversions
            }
        } else {
            let convertedInput;
            if(name === 'DAI') {
                convertedInput = ethers.utils.parseEther(inputCloseValue);
            } else {
                convertedInput = BigNumber.from(parseFloat(inputCloseValue) * 1e6); // six decimals for USDC and USDT tokens
            }
            if(convertedInput.gt(borrowed)) {
                window.alert(`Input amount cannot be higher than your ${name} balance borrowed on dForce`);
                return;
            } else {
                await closeTokenAmountWithFlashloan(name, convertedInput, false);
            }
        }
    }

    const priceDisplay = price.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2});
    const marketSizeDisplay = parseFloat(marketSize).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0});
    const liquidityDisplay = parseFloat(liquidity).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0});
    const flashloanMultiplier = ((100 + flashloanFee) / (100 + flashloanFee - parseFloat(LTV)));
    const suppliedDisplay = name === 'DAI' || selectedNetwork === 'BSC' ? (parseFloat(ethers.utils.formatEther(supplied)) / 1e18).toFixed(2) : (parseFloat(ethers.utils.formatEther(supplied)) / 1e6).toFixed(2);
    const borrowedDisplay = name === 'DAI' || selectedNetwork === 'BSC' ? parseFloat(ethers.utils.formatEther(borrowed)).toFixed(2) : ((borrowed.div(1e4)).toNumber() / 100).toFixed(2);
    const totalAPRsupplyDisplay = (parseFloat(supplyRate) + parseFloat(supplyDFrewards)).toFixed(2);
    const totalAPRborrowDisplay = (parseFloat(borrowRate) - parseFloat(borrowDFrewards)).toFixed(2);
    const currentBalanceDisplay = name === 'DAI' || selectedNetwork === 'BSC' ? parseFloat(ethers.utils.formatEther(currentBalance)).toFixed(2) : ((currentBalance.div(1e4)).toNumber() / 100).toFixed(2);
    const estimatedAPYvalueDisplay = estimatedAPYvalue.toFixed(2);
    const maxFoldUsersAmount = (Number(currentBalanceDisplay) * flashloanMultiplier).toFixed(2);
    const _totalAPR = (Number(totalAPRsupplyDisplay) * flashloanMultiplier) - (Number(totalAPRborrowDisplay) * (flashloanMultiplier - 1));
    const totalAPR = _totalAPR.toFixed(2);
    const _totalOutlay = Number(suppliedDisplay) - Number(borrowedDisplay);
    const totalOutlay = _totalOutlay.toFixed(0);
    const _usersAPR = (Number(suppliedDisplay) * Number(totalAPRsupplyDisplay)) - (Number(borrowedDisplay) * Number(totalAPRborrowDisplay));
    const usersAPR = _usersAPR === 0 && _totalOutlay === 0 ? 0.00 : (_usersAPR / _totalOutlay).toFixed(2);
    const estimatedYear = (Number(totalOutlay) * Number(totalAPR) / 100).toFixed(2)
    const estimatedMonth = (Number(estimatedYear) / 12).toFixed(2);
    const estimatedDay = (Number(estimatedYear) / 365).toFixed(2);
    if (totalSupply.toString() !== '0' && totalBorrows.toString() !== '0') {
        const oldSupplyIndex = supplyState.index.toBigInt();
        const newSupplyIndex = oldSupplyIndex + supplySpeed.toBigInt() * BigInt(referenceBlockNumber - supplyState.block) / totalSupply.toBigInt(); 
        const accruedSupply = (newSupplyIndex - supplierIndex.toBigInt()) * supplied.toBigInt() / BigInt(1e36);
        const oldBorrowIndex = borrowState.index.toBigInt();
        const newBorrowIndex = oldBorrowIndex + borrowSpeed.toBigInt() * BigInt(referenceBlockNumber - borrowState.block) / totalBorrows.toBigInt() * borrowIndex.toBigInt();
        const accruedBorrow = (newBorrowIndex - borrowerIndex.toBigInt()) * borrowed.toBigInt() / borrowIndex.toBigInt();
        totalPendingDFRewards = BigNumber.from(accruedSupply).add(accruedBorrow).add(pendingDFrewards);
    }
    const pendingDFrewardsDisplay = parseFloat(ethers.utils.formatEther(totalPendingDFRewards)).toFixed(6);
    const pendingDFrewardsValue = (
        parseFloat(ethers.utils.formatEther(totalPendingDFRewards)) * DFprice
    ).toFixed(2);

    return (
        
        <div key={name} style={{marginTop: "20px"}}>
            <div style={{display: "flex", flexDirection: "row"}}>
                <button >{name}</button>
                <div style={{marginLeft: "5px"}}>Price: {priceDisplay}</div>
                <div style={{marginLeft: "5px"}}>Market Size: {marketSizeDisplay}</div>
                <div style={{marginLeft: "5px"}}>Liquidity: {liquidityDisplay}</div>
                <div style={{marginLeft: "5px"}}>LTV: {LTV}% (max leverage: {flashloanMultiplier.toFixed(2)}x)</div>
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
                <div>Interest APR Supply: {supplyRate}%</div>
                <div style={{marginLeft: "5px"}}>Borrow: {borrowRate}%</div>
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
                <div>DF Rewards Supply: {supplyDFrewards}%</div>
                <div style={{marginLeft: "5px"}}>Borrow: {borrowDFrewards}%</div>
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
                <div>Total APR Supply: {totalAPRsupplyDisplay}%</div>
                <div style={{marginLeft: "5px"}}>Borrow: {totalAPRborrowDisplay}%</div>
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
                <div><b>Max APR: {totalAPR.toString()}%</b></div>
            </div>
            {supplied.toString() !== '0' && 
            <div>
                <br></br>
                <div>Supplied via Sickle: {suppliedDisplay}</div>
                <div>Borrowed via Sickle: {borrowedDisplay}</div>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div><b>You are staking {totalOutlay.toString()} {name} at an APR of {usersAPR.toString()}%</b></div>
                </div>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div>Estimated earnings: Day ${estimatedDay} Month ${estimatedMonth} Year ${estimatedYear}</div>
                </div>
            </div>}
            <br></br>
            <div style={{display: "flex", flexDirection: "row"}}>
                <button style={{width: "40%", textDecoration: "underline"}} onClick={() => onFoldTokenAmountWithFlashloan()}>
                    Fold {name} with flashloan (current balance: {currentBalanceDisplay})
                </button>
                <input type="text" placeholder="0" pattern="^[0-9]*[.,]?[0-9]*$" value={inputFoldValue} onChange={(e) => handleInputValue("fold", e)} maxLength={11}></input>
                <button style={{textDecoration: "underline", marginLeft: "10px"}} onClick={() => onMaxClick(Number(maxFoldUsersAmount))}>MAX</button>
                <div style={{marginLeft: "10px"}}>Estimated APR: {estimatedAPYvalueDisplay}%</div>
            </div>
            <div style={{display: "flex", flexDirection: "row", textDecoration: "underline"}}>
                <button style={{width: "40%"}} onClick={() => onCloseTokenAmountWithFlashloan(false)}>
                    Repay {name} debt with flashloan (currently borrowed: {borrowedDisplay})
                </button>
                <input type="text" placeholder="0" pattern="^[0-9]*[.,]?[0-9]*$" value={inputCloseValue} onChange={(e) => handleInputValue("close", e)} maxLength={11}></input>
                <button style={{width: "100px", marginLeft: "10px"}} onClick={() => onCloseTokenAmountWithFlashloan(true)}>CLOSE POSITION FULLY</button>
            </div>
            <div>
                <button style={{width: "500px"}} onClick={() => onClaimDFrewards()}>Claim {pendingDFrewardsDisplay} DF tokens (${pendingDFrewardsValue})</button>
            </div>
            {selectedNetwork !== "Arbitrum" && selectedNetwork !== "BSC" && 
            <div style={{marginTop: "5px"}}>
                <button style={{width: "500px"}} onClick={() => onCompoundDFrewards()}>Compound {pendingDFrewardsDisplay} DF tokens (${pendingDFrewardsValue}) with flashloan</button>
            </div>
            }
      </div>
    );
};

export default StrategyData;