import {FC, useLayoutEffect} from 'react';

type HomeTableProps = {
    displayProtocolData: (protocol: string, network: string) => void;
}

const HomeTable: FC<HomeTableProps> = ({
    displayProtocolData
}) => {
    
    const supportedProtocols = [
        {
            ID: 1,
            name: 'DForce Network',
            network: 'Ethereum',
            rewardTokens: 'DF',
            info: 'https://dforce.network/'
        },
        {
            ID: 2,
            name: 'DForce Network',
            network: 'Arbitrum',
            rewardTokens: 'DF',
            info: 'https://dforce.network/'
        },
        {
            ID: 3,
            name: 'DForce Network',
            network: 'Optimism',
            rewardTokens: 'DF',
            info: 'https://dforce.network/'
        },
        {
            ID: 4,
            name: 'DForce Network',
            network: 'BSC',
            rewardTokens: 'DF',
            info: 'https://dforce.network/'
        },
        {
            ID: 5,
            name: 'DForce Network',
            network: 'Polygon',
            rewardTokens: 'DF',
            info: 'https://dforce.network/'
        },
        {
            ID: 6,
            name: 'Aerodrome',
            network: 'Base',
            rewardTokens: 'AERO',
            info: 'https://aerodrome.finance/'
        },
    ];

    useLayoutEffect(() => {
        const protocol = supportedProtocols.find(x => x.ID === 6)
        if(protocol && protocol.name && protocol.network)
            displayProtocolData(protocol.name, protocol.network)
    }, [])

    return (
        <div>
            <div style={{marginTop: "20px"}}>
.------------------------------------------------------------------------------------------------------------------------.
<br/>
|                                                     Front Page                                                         |
<br/>
|------------------------------------------------------------------------------------------------------------------------|
<br/>
|         Protocol         |     Network      |    Reward Tokens     |                       INFO                        |
<br/>
|--------------------------|------------------|----------------------|---------------------------------------------------|
            </div>
{supportedProtocols.map(({ID, name, network, rewardTokens, info}) => (
    <div key={ID}>
        <div style={{
            display: "flex",
            justifyContent: "flex-start"
        }}>
            <div style={{flex: "0 0 193px"}}>
    | {name}
            </div>
            <div style={{flex: "0 0 136px"}}>
    | <a href="#" onClick={() => displayProtocolData(name, network)}>{network}</a>
            </div>
            <div style={{flex: "0 0 164px"}}>
    | {rewardTokens}
            </div>
            <div style={{flex: "0 0 372px"}}>
    | {info}
            </div>
            <div style={{flex: "0 0 1px"}}>
    |
            </div>
        </div>
    </div>
    ))
}
            <div>
'------------------------------------------------------------------------------------------------------------------------'
            </div>
        </div>
    )
};

export default HomeTable;