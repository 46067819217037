import {FC, useState, useEffect} from 'react';

const characters = ['[=...]', '[.=..]', '[..=.]', '[...=]' ];
const LOADER_STYLE = {
  fontFamily: 'Consolas, \"Liberation Mono\", Courier, monospace'
};

type LoaderProps = {
    speed: number,
}

const Loader:FC<LoaderProps> = ({speed}) => {

    const [tick, setTick] = useState<number>(0);

    useEffect(() => {
        const intervalID = setInterval(() => setTick((prevTick) => (prevTick + 1) % characters.length), speed);
        return() => clearInterval(intervalID);
     }, []);

      return (
        <span style={LOADER_STYLE}>{ characters[tick] }</span>
      );
  
}

export default Loader;