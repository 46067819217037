const Header = () => {

    let date = new Date();

    return (
        <div>
            <div style={{marginTop: "50px"}}>
                *************** 👨‍🌾 DEMO SICKLE APP 👨‍🌾 *************************************
                <br/>
                INFO  : https://vfat.io/
                <div style={{marginTop: "6px"}}>
                ****************************************************************************
                </div>
            </div>
            <div style={{marginTop: "20px"}}>
                {date.toString()}
            </div>
            <div style={{marginTop: "20px"}}>
                ****************************************************************************
            </div>
        </div>
    )

};

export default Header;