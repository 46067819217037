import {FC, useEffect, useState} from 'react';
import { ethers, ContractTransaction } from 'ethers';

import Loader from  '../Loader';
import DForceData from './DForce/DForceMainData';
import AerodromeData from './Aerodrome/AerodromeMainData';
import { ADDRESSES, ABIs } from '../ContractInfo';
import { chainIDregistry } from '../NetworkInfo';

type ProtocolsHeadDataProps = {
    selectedProtocol: string;
    selectedNetwork: string;
}

const ProtocolsHead: FC<ProtocolsHeadDataProps> = ({
  selectedProtocol, selectedNetwork
}) => {

    // UI data
    const [isLoadingWallet, setIsLoadingWallet] = useState<boolean>(false);
    const [isDeployingSickle, setIsDeployingSickle] = useState<boolean>(false);
    const [isTransactionInProgress, setIsTransactionInProgress] = useState<boolean>(false);
    const [isUpdatingData, setIsUpdatingData] = useState<boolean>(false);

    // wallet data
    const [, setOriginalProvider] = useState<ethers.providers.Provider>();
    const [currentProvider, setCurrentProvider] = useState<ethers.providers.Web3Provider>();
    const [currentAccount, setCurrentAccount] = useState<string>("");
    const [sickleAddress, setSickleAddress] = useState<string>("");

    useEffect (() => {
      
      const initialize = async () => {
        const account = window.sessionStorage.getItem("account")
        if(account) connectWallet(account)
      }

      initialize()
      
    }, [])

    const connectWallet = async (account?: string) => {
        if(!isLoadingWallet && !isDeployingSickle && !isTransactionInProgress && !isUpdatingData) {
          setIsLoadingWallet(true);
    
          let originalProvider;
          let provider;
          if(window.ethereum) {
            originalProvider = window.ethereum;
            try {
              provider = new ethers.providers.Web3Provider(originalProvider, "any");
              await provider.send("eth_requestAccounts", []);
            } catch(error) {
              window.alert('Could not connect Web3 wallet');
            }
          } else {
            setIsLoadingWallet(false);
            return;
          }
    
          if(provider) {
              try {
                // initiate Web3 and fetch wallet data
                const accounts = await provider.listAccounts();
                const network = await provider.getNetwork();

                if (accounts) {
                  if(account && account !== accounts[0]){
                    setIsLoadingWallet(false);
                    return
                  }
                  setCurrentAccount(accounts[0])
                  window.sessionStorage.setItem("account", accounts[0])
                }          

                setOriginalProvider(originalProvider as ethers.providers.Provider);
                setCurrentProvider(provider);
    
                if (network.chainId === chainIDregistry[selectedNetwork as keyof typeof chainIDregistry].chainID) { // check that user is connected to the correct network
                  
                  // fetch sickle address if sickle contracts are deployed and sickle address exists
                  
                  if(ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].SICKLE_INFRA.SickleFactory.length > 0) {
                    let SickleFactoryABI = new ethers.utils.Interface(JSON.stringify(ABIs.STUBS.SickleFactory));
                    const SickleFactory = new ethers.Contract(ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].SICKLE_INFRA.SickleFactory, SickleFactoryABI, provider.getSigner());
                    const sickleAddressFetched = await SickleFactory.sickles(accounts[0]);
      
                    if (sickleAddressFetched !== '0x0000000000000000000000000000000000000000') {
                      setSickleAddress(sickleAddressFetched);
                    }
                  } else {
                    window.alert("Sickle Factory contract is not yet deployed on this network.");
                  }
    
                  // stop displaying loader
    
                  setIsLoadingWallet(false);
    
                } else {

                  window.alert(`Your wallet is connected to the wrong network, please switch to the ${selectedNetwork} Mainnet.`);

                  await switchNetwork(selectedNetwork, provider);
                  setIsLoadingWallet(false);
                }

              } catch (error) {
                console.log(error);
                setIsLoadingWallet(false);
                window.alert('Could not fetch wallet data.');
                disconnectWallet();
              }
          }
        }
      }
    
    const disconnectWallet = () => {
        if(!isLoadingWallet && !isDeployingSickle && !isTransactionInProgress && !isUpdatingData) {
          setOriginalProvider(undefined);
          setCurrentProvider(undefined);
          setCurrentAccount("");
          setSickleAddress("");
          window.sessionStorage.removeItem("account")
        }
    }

    interface ProviderRpcError extends Error {
      code: number;
      message: string;
      data?: unknown;
    }

    const switchNetwork = async (selectedNetwork: string, providerInput: ethers.providers.Web3Provider) => {
      if (providerInput && providerInput?.provider.request) {
          try {
            await providerInput?.provider?.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: chainIDregistry[selectedNetwork as keyof typeof chainIDregistry].chainIDhex }]
            });
          } catch (err) {
            if ((err as ProviderRpcError).code === 4902) {
              try {
                await providerInput?.provider?.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: chainIDregistry[selectedNetwork as keyof typeof chainIDregistry].chainIDhex,
                      chainName: chainIDregistry[selectedNetwork as keyof typeof chainIDregistry].name,
                      rpcUrls: chainIDregistry[selectedNetwork as keyof typeof chainIDregistry].rpcUrls,
                      blockExplorerUrls: chainIDregistry[selectedNetwork as keyof typeof chainIDregistry].blockExplorerUrls,
                    },
                  ]
                });
              } catch (error) {
                window.alert('Could not switch to target network.');
                disconnectWallet();
                return;
              }
            } else {
                window.alert('Could not switch to target network.');
                disconnectWallet();
                return;
            }
          }
          connectWallet();
      }
    }

    const sendTransaction = async (transaction: ContractTransaction) : Promise<boolean> => {

        setIsTransactionInProgress(true);
    
        try {
          const tx = await transaction;
          const receipt = await tx.wait();
          if (receipt.status === 1) {
            window.alert('Transaction Confirmed !');
          } else {
            window.alert('Transaction Reverted !');
          }
          setIsTransactionInProgress(false);
          return true;
          } catch (err) {
            setIsTransactionInProgress(false);
            let message;
            err instanceof Error ? message = err.message : message = String(err);
            window.alert(message);
            return false;
          }
      }

    const deploySickle = async () => {

      if(ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].SICKLE_INFRA.SickleFactory.length === 0) {
        window.alert("Sickle Factory contract is not yet deployed on this network.");
      } else if(sickleAddress.length === 0 && currentAccount.length > 0 && !isDeployingSickle && !isLoadingWallet) {
          
          const res = window.confirm("WARNING : this is an experimental product. We highly recommend using a fresh address to deploy this sickle and to make sure it holds only the assets that you intend to use farming strategies with.");
    
          if(!res) {
            return;
          } else {
            setIsDeployingSickle(true);
    
            // deploy sickle and store address in state
            let SickleFactoryABI = new ethers.utils.Interface(JSON.stringify(ABIs.STUBS.SickleFactory));
            const SickleFactory = new ethers.Contract(ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].SICKLE_INFRA.SickleFactory, SickleFactoryABI, currentProvider?.getSigner());
      
            await sendTransaction(SickleFactory.deploy([], [], [], [], ethers.constants.HashZero));
      
            try {
              const deployedSickleAddress = await SickleFactory.sickles(currentAccount);
              if(deployedSickleAddress !== '0x0000000000000000000000000000000000000000') setSickleAddress(deployedSickleAddress);
            } catch(err) {
              console.log(err);
            }
            setIsDeployingSickle(false);
          }
        }
    }

    return (
        <div>
            {currentAccount.length === 0 ? 
            <div style={{marginTop: "20px"}}>
                <button className="link-button" id="connect_wallet_button" onClick={() => connectWallet()}>[CONNECT WALLET]</button>
            </div> :
            <div style={{marginTop: "20px"}}>
                <button id="disconnect_wallet_button" onClick={() => disconnectWallet()}>[DISCONNECT WALLET]</button>
            </div>}
            {currentAccount.length === 0 && !isLoadingWallet ? 
                <div style={{marginTop: "20px"}}>
                Wallet not initialized
            </div> : 
            isLoadingWallet ?
                <div style={{marginTop: "20px"}}>
                Loading wallet...{' '}
                <Loader speed={50}/>
                </div> :
            <div style={{marginTop: "20px"}}>
            {`Initialized ${currentAccount}`}
            </div>
            }
            {sickleAddress.length === 0 ? 
            <div style={{marginTop: "20px"}}>
                <button  id="deploy_sickle_button" onClick={() => deploySickle()}>[DEPLOY SICKLE]</button>
            </div> : 
                <div style={{marginTop: "20px"}}>
                [SICKLE DEPLOYED]
                </div>
            }
            {sickleAddress.length === 0 && !isDeployingSickle ? 
            <div style={{marginTop: "20px"}}>
                Sickle not deployed
            </div> : 
            sickleAddress.length === 0 && isDeployingSickle ?
            <div style={{marginTop: "20px"}}>
                Deploying Sickle...{' '}
                <Loader speed={50}/>
            </div> :
                <div style={{marginTop: "20px"}}>
                {`Sickle deployed at ${sickleAddress}`}
            </div>
            }
            {isTransactionInProgress && !isDeployingSickle ?
            <div style={{marginTop: "20px"}}>
                Transaction in progress...{' '}
                <Loader speed={50}/>
                </div> : null}
            {isUpdatingData ?
            <div style={{marginTop: "20px"}}>
                Fetching fresh data...{' '}
                <Loader speed={50}/>
                </div> : null}
        {selectedProtocol === "DForce Network" && currentAccount.length > 0 && !isLoadingWallet ? 
        <DForceData 
          currentAccount={currentAccount}
          currentProvider={currentProvider}
          sickleAddress={sickleAddress}
          setIsUpdatingData={setIsUpdatingData}
          isDeployingSickle={isDeployingSickle}
          sendTransaction={sendTransaction}
          selectedNetwork={selectedNetwork}
          /> : null}
        {selectedProtocol === "Aerodrome" && currentAccount.length > 0 && !isLoadingWallet ? 
        <AerodromeData 
          currentAccount={currentAccount}
          currentProvider={currentProvider}
          sickleAddress={sickleAddress}
          setIsUpdatingData={setIsUpdatingData}
          isDeployingSickle={isDeployingSickle}
          sendTransaction={sendTransaction}
          selectedNetwork={selectedNetwork}
          /> : null}
        </div>
    )

};

export default ProtocolsHead;