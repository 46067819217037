import { FC, useState, useEffect } from "react";
import { ethers, ContractTransaction, BigNumber } from "ethers";
import {
  Multicall,
  ContractCallResults,
  ContractCallContext,
} from "ethereum-multicall";
import CoinGecko from 'coingecko-api';

import StrategyData from "./DForceStrategyData";
import { ADDRESSES, ABIs } from "../../ContractInfo";

// SWAPS
// Optimism --> velodrome DF/USX + USX/USDC + USDC/stable if necessary
// Ethereum --> uniswapV2 DF/WETH and WETH/stable
// Arbitrum --> no on-chain liquidity between DF and stables, disable compound action
// Polygon --> uniswapV3 DF/USDC + USDC/stable if necessary
// BSC --> no on-chain liquidity between DF and stables, disable compound action

type DForceDataProps = {
  currentAccount: string;
  currentProvider: ethers.providers.Web3Provider | undefined;
  sickleAddress: string;
  setIsUpdatingData: (isUpdatingData: boolean) => void;
  isDeployingSickle: boolean;
  sendTransaction: (
    transaction: ContractTransaction
  ) => Promise<boolean>;
  selectedNetwork: string;
};

type CompState = {
  block: number;
  index: BigNumber;
};

interface AssetInfo {
  name: string;
  price: number; // in USD per Chainlink feeds
  marketSize: string; // in USD
  liquidity: string; // in USD
  LTV: string; // in %
  supplied: BigNumber; // nb of tokens
  borrowed: BigNumber; // nb of tokens
  supplyRate: string; // in %
  borrowRate: string; // in %
  supplyDFrewards: string; // in %
  borrowDFrewards: string; // in %
  currentBalance: BigNumber; // nb of tokens
  flashloanFee: number; // in %
  approvalToSickle: BigNumber; // in nb of tokens
  supplyState: CompState;
  borrowState: CompState;
  totalBorrows: BigNumber;
  totalSupply: BigNumber;
  supplySpeed: BigNumber;
  borrowSpeed: BigNumber;
  supplierIndex: BigNumber;
  borrowerIndex: BigNumber;
  borrowIndex: BigNumber;
}

const DForceData: FC<DForceDataProps> = ({
  currentAccount,
  currentProvider,
  sickleAddress,
  setIsUpdatingData,
  isDeployingSickle,
  sendTransaction,
  selectedNetwork
}) => {
  // state

  const [txCounter, setTXcounter] = useState<number>(0);
  const [sickleAccountEquity, setSickleAccountEquity] =
    useState<string>("$0.00");
  const [pendingDFrewards, setPendingDFrewards] = useState<BigNumber>(
    BigNumber.from(0)
  );
  const [DFprice, setDFprice] = useState<number>(0);
  const [referenceBlockNumber, setReferenceBlockNumber] = useState<number>(0);
  const [assetList, setAssetList] = useState<AssetInfo[]>([
    {
      name: "USDC",
      price: 1.0, // in USD per Chainlink feed
      marketSize: "", // nb of tokens
      liquidity: "", // nb of tokens
      LTV: "", // in %
      supplied: BigNumber.from(0), // nb of tokens
      borrowed: BigNumber.from(0), // nb of tokens
      supplyRate: "0.00", // in %
      borrowRate: "0.00", // in %
      supplyDFrewards: "0.00", // in %
      borrowDFrewards: "0.00", // in %
      currentBalance: BigNumber.from(0), // nb of tokens
      flashloanFee: 0.01, // in %
      approvalToSickle: BigNumber.from(0), // in nb of tokens
      supplyState: { block: 0, index: BigNumber.from(0) },
      borrowState: { block: 0, index: BigNumber.from(0) },
      totalBorrows: BigNumber.from(0),
      totalSupply: BigNumber.from(0),
      supplySpeed: BigNumber.from(0),
      borrowSpeed: BigNumber.from(0),
      supplierIndex: BigNumber.from(0),
      borrowerIndex: BigNumber.from(0),
      borrowIndex: BigNumber.from(0),
    },
    {
      name: "USDT",
      price: 1.0, // in USD per Chainlink feed
      marketSize: "", // nb of tokens
      liquidity: "", // nb of tokens
      LTV: "", // in %
      supplied: BigNumber.from(0), // nb of tokens
      borrowed: BigNumber.from(0), // nb of tokens
      supplyRate: "0.00", // in %
      borrowRate: "0.00", // in %
      supplyDFrewards: "0.00", // in %
      borrowDFrewards: "0.00", // in %
      currentBalance: BigNumber.from(0), // nb of tokens
      flashloanFee: 0.01, // in %
      approvalToSickle: BigNumber.from(0), // in nb of tokens
      supplyState: { block: 0, index: BigNumber.from(0) },
      borrowState: { block: 0, index: BigNumber.from(0) },
      totalBorrows: BigNumber.from(0),
      totalSupply: BigNumber.from(0),
      supplySpeed: BigNumber.from(0),
      borrowSpeed: BigNumber.from(0),
      supplierIndex: BigNumber.from(0),
      borrowerIndex: BigNumber.from(0),
      borrowIndex: BigNumber.from(0),
    },
    {
      name: "DAI",
      price: 1.0, // in USD per Chainlink feed
      marketSize: "", // nb of tokens
      liquidity: "", // nb of tokens
      LTV: "", // in %
      supplied: BigNumber.from(0), // nb of tokens
      borrowed: BigNumber.from(0), // nb of tokens
      supplyRate: "0.00", // in %
      borrowRate: "0.00", // in %
      supplyDFrewards: "0.00", // in %
      borrowDFrewards: "0.00", // in %
      currentBalance: BigNumber.from(0), // nb of tokens
      flashloanFee: 0.01, // in %
      approvalToSickle: BigNumber.from(0), // in nb of tokens
      supplyState: { block: 0, index: BigNumber.from(0) },
      borrowState: { block: 0, index: BigNumber.from(0) },
      totalBorrows: BigNumber.from(0),
      totalSupply: BigNumber.from(0),
      supplySpeed: BigNumber.from(0),
      borrowSpeed: BigNumber.from(0),
      supplierIndex: BigNumber.from(0),
      borrowerIndex: BigNumber.from(0),
      borrowIndex: BigNumber.from(0),
    },
  ]);

  // functions

  useEffect(() => {
    // fetch balances & contract data

    setIsUpdatingData(true);    

    const fetchBalancesAndContractData = async () => {
      if (currentProvider) {
        const network = await currentProvider.getNetwork();

        let newAssetList = [...assetList];

        const multicall = new Multicall({
          ethersProvider: currentProvider,
          tryAggregate: true,
        });

        const contractCallContext: ContractCallContext[] = [
          {
            reference: "ChainlinkUSDCpriceFeed",
            contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].CHAINLINK?.USDCpriceFeed ?? "",
            abi: ABIs.CHAINLINK.EACAggregatorProxy,
            calls: [
              {
                reference: "USDCprice",
                methodName: "latestRoundData",
                methodParameters: [],
              },
            ],
          },
          {
            reference: "ChainlinkUSDTpriceFeed",
            contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].CHAINLINK?.USDTpriceFeed ?? "",
            abi: ABIs.CHAINLINK.EACAggregatorProxy,
            calls: [
              {
                reference: "USDTprice",
                methodName: "latestRoundData",
                methodParameters: [],
              },
            ],
          },
          {
            reference: "ChainlinkDAIpriceFeed",
            contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].CHAINLINK?.DAIpriceFeed ?? "",
            abi: ABIs.CHAINLINK.EACAggregatorProxy,
            calls: [
              {
                reference: "DAIprice",
                methodName: "latestRoundData",
                methodParameters: [],
              },
            ],
          },
          {
            reference: "Controller",
            contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.Controller ?? "",
            abi: ABIs.DFORCE.Controller,
            calls: [
              {
                reference: "iUSDC_MARKET_DATA",
                methodName: "markets",
                methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDC],
              },
              {
                reference: "iUSDT_MARKET_DATA",
                methodName: "markets",
                methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDT],
              },
              {
                reference: "iDAI_MARKET_DATA",
                methodName: "markets",
                methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iDAI],
              },
            ],
          },
          {
            reference: "iUSDC",
            contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDC ?? "",
            abi: ABIs.DFORCE.iToken,
            calls: [
              {
                reference: "iUSDC_CASH",
                methodName: "getCash",
                methodParameters: [],
              },
              {
                reference: "iUSDC_BORROWS",
                methodName: "totalBorrows",
                methodParameters: [],
              },
              {
                reference: "iUSDC_SUPPLY_RATE",
                methodName: "supplyRatePerBlock",
                methodParameters: [],
              },
              {
                reference: "iUSDC_BORROW_RATE",
                methodName: "borrowRatePerBlock",
                methodParameters: [],
              },
              {
                reference: "iUSDC_TOTAL_SUPPLY",
                methodName: "totalSupply",
                methodParameters: [],
              },
              { 
                reference: "iUSDC_BORROW_INDEX",
                methodName: "borrowIndex",
                methodParameters: [],
              },
            ],
          },
          {
            reference: "iUSDT",
            contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDT ?? "",
            abi: ABIs.DFORCE.iToken,
            calls: [
              {
                reference: "iUSDT_CASH",
                methodName: "getCash",
                methodParameters: [],
              },
              {
                reference: "iUSDT_BORROWS",
                methodName: "totalBorrows",
                methodParameters: [],
              },
              {
                reference: "iUSDT_SUPPLY_RATE",
                methodName: "supplyRatePerBlock",
                methodParameters: [],
              },
              {
                reference: "iUSDT_BORROW_RATE",
                methodName: "borrowRatePerBlock",
                methodParameters: [],
              },
              {
                reference: "iUSDT_TOTAL_SUPPLY",
                methodName: "totalSupply",
                methodParameters: [],
              },
              { 
                reference: "iUSDT_BORROW_INDEX",
                methodName: "borrowIndex",
                methodParameters: [],
              },
            ],
          },
          {
            reference: "iDAI",
            contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iDAI ?? "",
            abi: ABIs.DFORCE.iToken,
            calls: [
              {
                reference: "iDAI_CASH",
                methodName: "getCash",
                methodParameters: [],
              },
              {
                reference: "iDAI_BORROWS",
                methodName: "totalBorrows",
                methodParameters: [],
              },
              {
                reference: "iDAI_SUPPLY_RATE",
                methodName: "supplyRatePerBlock",
                methodParameters: [],
              },
              {
                reference: "iDAI_BORROW_RATE",
                methodName: "borrowRatePerBlock",
                methodParameters: [],
              },
              {
                reference: "iDAI_TOTAL_SUPPLY",
                methodName: "totalSupply",
                methodParameters: [],
              },
              { 
                reference: "iDAI_BORROW_INDEX",
                methodName: "borrowIndex",
                methodParameters: [],
              },
            ],
          },
          {
            reference: "RewardDistributorV3",
            contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.RewardDistributorV3 ?? "",
            abi: ABIs.DFORCE.RewardDistributorV3,
            calls: [
              {
                reference: "iUSDC_DF_SUPPLY_RATE",
                methodName: "distributionSupplySpeed",
                methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDC],
              },
              {
                reference: "iUSDT_DF_SUPPLY_RATE",
                methodName: "distributionSupplySpeed",
                methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDT],
              },
              {
                reference: "iDAI_DF_SUPPLY_RATE",
                methodName: "distributionSupplySpeed",
                methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iDAI],
              },
              {
                reference: "iUSDC_DF_BORROW_RATE",
                methodName: "distributionSpeed",
                methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDC],
              },
              {
                reference: "iUSDT_DF_BORROW_RATE",
                methodName: "distributionSpeed",
                methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDT],
              },
              {
                reference: "iDAI_DF_BORROW_RATE",
                methodName: "distributionSpeed",
                methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iDAI],
              },
              {
                reference: "iUSDC_DF_SUPPLY_STATE",
                methodName: "distributionSupplyState",
                methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDC],
              },
              {
                reference: "iUSDT_DF_SUPPLY_STATE",
                methodName: "distributionSupplyState",
                methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDT],
              },
              {
                reference: "iDAI_DF_SUPPLY_STATE",
                methodName: "distributionSupplyState",
                methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iDAI],
              },
              {
                reference: "iUSDC_DF_BORROW_STATE",
                methodName: "distributionBorrowState",
                methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDC],
              },
              {
                reference: "iUSDT_DF_BORROW_STATE",
                methodName: "distributionBorrowState",
                methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDT],
              },
              {
                reference: "iDAI_DF_BORROW_STATE",
                methodName: "distributionBorrowState",
                methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iDAI],
              },
            ],
          },
          {
            reference: "USDC",
            contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.USDC ?? "",
            abi: ABIs.DFORCE.iToken,
            calls: [
              {
                reference: "USDC_BALANCE",
                methodName: "balanceOf",
                methodParameters: [currentAccount],
              },
            ],
          },
          {
            reference: "USDT",
            contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.USDT ?? "",
            abi: ABIs.DFORCE.iToken,
            calls: [
              {
                reference: "USDT_BALANCE",
                methodName: "balanceOf",
                methodParameters: [currentAccount],
              },
            ],
          },
          {
            reference: "DAI",
            contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.DAI ?? "",
            abi: ABIs.DFORCE.iToken,
            calls: [
              {
                reference: "DAI_BALANCE",
                methodName: "balanceOf",
                methodParameters: [currentAccount],
              },
            ],
          },
        ];

        const results: ContractCallResults = await multicall.call(
          contractCallContext
        );

        const {
          results: {
            ChainlinkUSDCpriceFeed: {
              callsReturnContext: {
                0: {
                  returnValues: {
                    1: { hex: USDC_PRICE },
                  },
                },
              },
            },
          },
          results: {
            ChainlinkUSDTpriceFeed: {
              callsReturnContext: {
                0: {
                  returnValues: {
                    1: { hex: USDT_PRICE },
                  },
                },
              },
            },
          },
          results: {
            ChainlinkDAIpriceFeed: {
              callsReturnContext: {
                0: {
                  returnValues: {
                    1: { hex: DAI_PRICE },
                  },
                },
              },
            },
          },
          results: {
            Controller: {
              callsReturnContext: {
                0: {
                  returnValues: {
                    0: { hex: iUSDC_COLLATERAL_FACTOR },
                  },
                },
              },
            },
          },
          results: {
            Controller: {
              callsReturnContext: {
                1: {
                  returnValues: {
                    0: { hex: iUSDT_COLLATERAL_FACTOR },
                  },
                },
              },
            },
          },
          results: {
            Controller: {
              callsReturnContext: {
                2: {
                  returnValues: {
                    0: { hex: iDAI_COLLATERAL_FACTOR },
                  },
                },
              },
            },
          },
          results: {
            iUSDC: {
              callsReturnContext: {
                0: {
                  returnValues: {
                    0: { hex: iUSDC_CASH },
                  },
                },
              },
            },
          },
          results: {
            iUSDC: {
              callsReturnContext: {
                1: {
                  returnValues: {
                    0: { hex: iUSDC_BORROWS },
                  },
                },
              },
            },
          },
          results: {
            iUSDC: {
              callsReturnContext: {
                2: {
                  returnValues: {
                    0: { hex: iUSDC_SUPPLY_RATE },
                  },
                },
              },
            },
          },
          results: {
            iUSDC: {
              callsReturnContext: {
                3: {
                  returnValues: {
                    0: { hex: iUSDC_BORROW_RATE },
                  },
                },
              },
            },
          },
          results: {
            iUSDC: {
              callsReturnContext: {
                4: {
                  returnValues: {
                    0: { hex: iUSDC_TOTAL_SUPPLY },
                  },
                },
              },
            },
          },
          results: {
            iUSDC: {
              callsReturnContext: {
                5: {
                  returnValues: {
                    0: { hex: iUSDC_BORROW_INDEX },
                  },
                },
              },
            },
          },
          results: {
            iUSDT: {
              callsReturnContext: {
                0: {
                  returnValues: {
                    0: { hex: iUSDT_CASH },
                  },
                },
              },
            },
          },
          results: {
            iUSDT: {
              callsReturnContext: {
                1: {
                  returnValues: {
                    0: { hex: iUSDT_BORROWS },
                  },
                },
              },
            },
          },
          results: {
            iUSDT: {
              callsReturnContext: {
                2: {
                  returnValues: {
                    0: { hex: iUSDT_SUPPLY_RATE },
                  },
                },
              },
            },
          },
          results: {
            iUSDT: {
              callsReturnContext: {
                3: {
                  returnValues: {
                    0: { hex: iUSDT_BORROW_RATE },
                  },
                },
              },
            },
          },
          results: {
            iUSDT: {
              callsReturnContext: {
                4: {
                  returnValues: {
                    0: { hex: iUSDT_TOTAL_SUPPLY },
                  },
                },
              },
            },
          },
          results: {
            iUSDT: {
              callsReturnContext: {
                5: {
                  returnValues: {
                    0: { hex: iUSDT_BORROW_INDEX },
                  },
                },
              },
            },
          },
          results: {
            iDAI: {
              callsReturnContext: {
                0: {
                  returnValues: {
                    0: { hex: iDAI_CASH },
                  },
                },
              },
            },
          },
          results: {
            iDAI: {
              callsReturnContext: {
                1: {
                  returnValues: {
                    0: { hex: iDAI_BORROWS },
                  },
                },
              },
            },
          },
          results: {
            iDAI: {
              callsReturnContext: {
                2: {
                  returnValues: {
                    0: { hex: iDAI_SUPPLY_RATE },
                  },
                },
              },
            },
          },
          results: {
            iDAI: {
              callsReturnContext: {
                3: {
                  returnValues: {
                    0: { hex: iDAI_BORROW_RATE },
                  },
                },
              },
            },
          },
          results: {
            iDAI: {
              callsReturnContext: {
                4: {
                  returnValues: {
                    0: { hex: iDAI_TOTAL_SUPPLY },
                  },
                },
              },
            },
          },
          results: {
            iDAI: {
              callsReturnContext: {
                5: {
                  returnValues: {
                    0: { hex: iDAI_BORROW_INDEX },
                  },
                },
              },
            },
          },
          results: {
            RewardDistributorV3: {
              callsReturnContext: {
                0: {
                  returnValues: {
                    0: { hex: iUSDC_DF_SUPPLY_RATE },
                  },
                },
              },
            },
          },
          results: {
            RewardDistributorV3: {
              callsReturnContext: {
                1: {
                  returnValues: {
                    0: { hex: iUSDT_DF_SUPPLY_RATE },
                  },
                },
              },
            },
          },
          results: {
            RewardDistributorV3: {
              callsReturnContext: {
                2: {
                  returnValues: {
                    0: { hex: iDAI_DF_SUPPLY_RATE },
                  },
                },
              },
            },
          },
          results: {
            RewardDistributorV3: {
              callsReturnContext: {
                3: {
                  returnValues: {
                    0: { hex: iUSDC_DF_BORROW_RATE },
                  },
                },
              },
            },
          },
          results: {
            RewardDistributorV3: {
              callsReturnContext: {
                4: {
                  returnValues: {
                    0: { hex: iUSDT_DF_BORROW_RATE },
                  },
                },
              },
            },
          },
          results: {
            RewardDistributorV3: {
              callsReturnContext: {
                5: {
                  returnValues: {
                    0: { hex: iDAI_DF_BORROW_RATE },
                  },
                },
              },
            },
          },
          results: {
            RewardDistributorV3: {
              callsReturnContext: {
                6: {
                  returnValues: {
                    0: { hex: iUSDC_DF_SUPPLY_STATE_INDEX },
                    1: { hex: iUSDC_DF_SUPPLY_STATE_BLOCK_NUMBER },
                  },
                },
              },
            },
          },
          results: {
            RewardDistributorV3: {
              callsReturnContext: {
                7: {
                  returnValues: {
                    0: { hex: iUSDT_DF_SUPPLY_STATE_INDEX },
                    1: { hex: iUSDT_DF_SUPPLY_STATE_BLOCK_NUMBER },
                  },
                },
              },
            },
          },
          results: {
            RewardDistributorV3: {
              callsReturnContext: {
                8: {
                  returnValues: {
                    0: { hex: iDAI_DF_SUPPLY_STATE_INDEX },
                    1: { hex: iDAI_DF_SUPPLY_STATE_BLOCK_NUMBER },
                  },
                },
              },
            },
          },
          results: {
            RewardDistributorV3: {
              callsReturnContext: {
                9: {
                  returnValues: {
                    0: { hex: iUSDC_DF_BORROW_STATE_INDEX },
                    1: { hex: iUSDC_DF_BORROW_STATE_BLOCK_NUMBER },
                  },
                },
              },
            },
          },
          results: {
            RewardDistributorV3: {
              callsReturnContext: {
                10: {
                  returnValues: {
                    0: { hex: iUSDT_DF_BORROW_STATE_INDEX },
                    1: { hex: iUSDT_DF_BORROW_STATE_BLOCK_NUMBER },
                  },
                },
              },
            },
          },
          results: {
            RewardDistributorV3: {
              callsReturnContext: {
                11: {
                  returnValues: {
                    0: { hex: iDAI_DF_BORROW_STATE_INDEX },
                    1: { hex: iDAI_DF_BORROW_STATE_BLOCK_NUMBER },
                  },
                },
              },
            },
          },
          results: {
            USDC: {
              callsReturnContext: {
                0: {
                  returnValues: {
                    0: { hex: USDC_BALANCE },
                  },
                },
              },
            },
          },
          results: {
            USDT: {
              callsReturnContext: {
                0: {
                  returnValues: {
                    0: { hex: USDT_BALANCE },
                  },
                },
              },
            },
          },
          results: {
            DAI: {
              callsReturnContext: {
                0: {
                  returnValues: {
                    0: { hex: DAI_BALANCE },
                  },
                },
              },
            },
          },
        } = results;

        if (sickleAddress.length > 0) {
          const contractCallContext2: ContractCallContext[] = [
            {
              reference: "RewardDistributorV3",
              contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.RewardDistributorV3 ?? "",
              abi: ABIs.DFORCE.RewardDistributorV3,
              calls: [
                {
                  reference: "pendingSickleDFrewards",
                  methodName: "reward",
                  methodParameters: [sickleAddress],
                },
                {
                  reference: "iUSDC_SUPPLIER_INDEX",
                  methodName: "distributionSupplierIndex",
                  methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDC, sickleAddress],
                },
                {
                  reference: "iUSDT_SUPPLIER_INDEX",
                  methodName: "distributionSupplierIndex",
                  methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDT, sickleAddress],
                },
                {
                  reference: "iDAI_SUPPLIER_INDEX",
                  methodName: "distributionSupplierIndex",
                  methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iDAI, sickleAddress],
                },
                {
                  reference: "iUSDC_BORROWER_INDEX",
                  methodName: "distributionBorrowerIndex",
                  methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDC, sickleAddress],
                },
                {
                  reference: "iUSDT_BORROWER_INDEX",
                  methodName: "distributionBorrowerIndex",
                  methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDT, sickleAddress],
                },
                {
                  reference: "iDAI_BORROWER_INDEX",
                  methodName: "distributionBorrowerIndex",
                  methodParameters: [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iDAI, sickleAddress],
                },
              ],
            },
            {
              reference: "iUSDC",
              contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDC ?? "",
              abi: ABIs.DFORCE.iToken,
              calls: [
                {
                  reference: "iUSDC_BALANCE",
                  methodName: "balanceOf",
                  methodParameters: [sickleAddress],
                },
                {
                  reference: "iUSDC_EXCHANGE_RATE",
                  methodName: "exchangeRateStored",
                  methodParameters: [],
                },
                {
                  reference: "iUSDC_BORROWED",
                  methodName: "borrowBalanceStored",
                  methodParameters: [sickleAddress],
                },
              ],
            },
            {
              reference: "iUSDT",
              contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDT ?? "",
              abi: ABIs.DFORCE.iToken,
              calls: [
                {
                  reference: "iUSDT_BALANCE",
                  methodName: "balanceOf",
                  methodParameters: [sickleAddress],
                },
                {
                  reference: "iUSDT_EXCHANGE_RATE",
                  methodName: "exchangeRateStored",
                  methodParameters: [],
                },
                {
                  reference: "iUSDT_BORROWED",
                  methodName: "borrowBalanceStored",
                  methodParameters: [sickleAddress],
                },
              ],
            },
            {
              reference: "iDAI",
              contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iDAI ?? "",
              abi: ABIs.DFORCE.iToken,
              calls: [
                {
                  reference: "iDAI_BALANCE",
                  methodName: "balanceOf",
                  methodParameters: [sickleAddress],
                },
                {
                  reference: "iDAI_EXCHANGE_RATE",
                  methodName: "exchangeRateStored",
                  methodParameters: [],
                },
                {
                  reference: "iDAI_BORROWED",
                  methodName: "borrowBalanceStored",
                  methodParameters: [sickleAddress],
                },
              ],
            },
            {
              reference: "USDC",
              contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.USDC ?? "",
              abi: ABIs.DFORCE.iToken,
              calls: [
                {
                  reference: "USDC_APPROVAL",
                  methodName: "allowance",
                  methodParameters: [currentAccount, sickleAddress],
                },
              ],
            },
            {
              reference: "USDT",
              contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.USDT ?? "",
              abi: ABIs.DFORCE.iToken,
              calls: [
                {
                  reference: "USDT_APPROVAL",
                  methodName: "allowance",
                  methodParameters: [currentAccount, sickleAddress],
                },
              ],
            },
            {
              reference: "DAI",
              contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.DAI ?? "",
              abi: ABIs.DFORCE.iToken,
              calls: [
                {
                  reference: "DAI_APPROVAL",
                  methodName: "allowance",
                  methodParameters: [currentAccount, sickleAddress],
                },
              ],
            },
            {
              reference: "Controller",
              contractAddress: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.Controller?? "",
              abi: ABIs.DFORCE.Controller,
              calls: [
                {
                  reference: "ACCOUNT_EQUITY",
                  methodName: "calcAccountEquity",
                  methodParameters: [sickleAddress],
                },
              ],
            },
          ];

          const results2: ContractCallResults = await multicall.call(
            contractCallContext2
          );

          const {
            results: {
              RewardDistributorV3: {
                callsReturnContext: {
                  0: {
                    returnValues: {
                      0: { hex: pendingSickleDFrewards },
                    },
                  },
                },
              },
            },
            results: {
              RewardDistributorV3: {
                callsReturnContext: {
                  1: {
                    returnValues: {
                      0: { hex: iUSDC_SUPPLIER_INDEX },
                    },
                  },
                },
              },
            },
            results: {
              RewardDistributorV3: {
                callsReturnContext: {
                  2: {
                    returnValues: {
                      0: { hex: iUSDT_SUPPLIER_INDEX },
                    },
                  },
                },
              },
            },
            results: {
              RewardDistributorV3: {
                callsReturnContext: {
                  3: {
                    returnValues: {
                      0: { hex: iDAI_SUPPLIER_INDEX },
                    },
                  },
                },
              },
            },
            results: {
              RewardDistributorV3: {
                callsReturnContext: {
                  4: {
                    returnValues: {
                      0: { hex: iUSDC_BORROWER_INDEX },
                    },
                  },
                },
              },
            },
            results: {
              RewardDistributorV3: {
                callsReturnContext: {
                  5: {
                    returnValues: {
                      0: { hex: iUSDT_BORROWER_INDEX },
                    },
                  },
                },
              },
            },
            results: {
              RewardDistributorV3: {
                callsReturnContext: {
                  6: {
                    returnValues: {
                      0: { hex: iDAI_BORROWER_INDEX },
                    },
                  },
                },
              },
            },
            results: {
              iUSDC: {
                callsReturnContext: {
                  0: {
                    returnValues: {
                      0: { hex: iUSDC_BALANCE },
                    },
                  },
                },
              },
            },
            results: {
              iUSDC: {
                callsReturnContext: {
                  1: {
                    returnValues: {
                      0: { hex: iUSDC_EXCHANGE_RATE },
                    },
                  },
                },
              },
            },
            results: {
              iUSDC: {
                callsReturnContext: {
                  2: {
                    returnValues: {
                      0: { hex: iUSDC_BORROWED },
                    },
                  },
                },
              },
            },
            results: {
              iUSDT: {
                callsReturnContext: {
                  0: {
                    returnValues: {
                      0: { hex: iUSDT_BALANCE },
                    },
                  },
                },
              },
            },
            results: {
              iUSDT: {
                callsReturnContext: {
                  1: {
                    returnValues: {
                      0: { hex: iUSDT_EXCHANGE_RATE },
                    },
                  },
                },
              },
            },
            results: {
              iUSDT: {
                callsReturnContext: {
                  2: {
                    returnValues: {
                      0: { hex: iUSDT_BORROWED },
                    },
                  },
                },
              },
            },
            results: {
              iDAI: {
                callsReturnContext: {
                  0: {
                    returnValues: {
                      0: { hex: iDAI_BALANCE },
                    },
                  },
                },
              },
            },
            results: {
              iDAI: {
                callsReturnContext: {
                  1: {
                    returnValues: {
                      0: { hex: iDAI_EXCHANGE_RATE },
                    },
                  },
                },
              },
            },
            results: {
              iDAI: {
                callsReturnContext: {
                  2: {
                    returnValues: {
                      0: { hex: iDAI_BORROWED },
                    },
                  },
                },
              },
            },
            results: {
              USDC: {
                callsReturnContext: {
                  0: {
                    returnValues: {
                      0: { hex: USDC_APPROVAL },
                    },
                  },
                },
              },
            },
            results: {
              USDT: {
                callsReturnContext: {
                  0: {
                    returnValues: {
                      0: { hex: USDT_APPROVAL },
                    },
                  },
                },
              },
            },
            results: {
              DAI: {
                callsReturnContext: {
                  0: {
                    returnValues: {
                      0: { hex: DAI_APPROVAL },
                    },
                  },
                },
              },
            },
            results: {
              Controller: {
                callsReturnContext: {
                  0: { returnValues: ACCOUNT_EQUITY },
                },
              },
            },
          } = results2;

          setPendingDFrewards(BigNumber.from(pendingSickleDFrewards));

          // convert and store sickle account equity, warning if account is in shortfall
          if (BigNumber.from(ACCOUNT_EQUITY[0].hex).gt(0)) {
            setSickleAccountEquity(
              (
                parseFloat(
                  ethers.utils.formatEther(
                    BigNumber.from(ACCOUNT_EQUITY[0].hex)
                  )
                ) / 1e18
              ).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            );
          } else if (BigNumber.from(ACCOUNT_EQUITY[1].hex).gt(0)) {
            setSickleAccountEquity(
              `-${(
                parseFloat(
                  ethers.utils.formatEther(
                    BigNumber.from(ACCOUNT_EQUITY[1].hex)
                  )
                ) / 1e18
              ).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} -- ACCOUNT LIQUIDATABLE !`
            );
          } else {
            setSickleAccountEquity("$0.00");
          }

          newAssetList[0].supplied = BigNumber.from(iUSDC_BALANCE).mul(
            BigNumber.from(iUSDC_EXCHANGE_RATE)
          );
          newAssetList[1].supplied = BigNumber.from(iUSDT_BALANCE).mul(
            BigNumber.from(iUSDT_EXCHANGE_RATE)
          );
          newAssetList[2].supplied = BigNumber.from(iDAI_BALANCE).mul(
            BigNumber.from(iDAI_EXCHANGE_RATE)
          );
          newAssetList[0].borrowed = BigNumber.from(iUSDC_BORROWED);
          newAssetList[1].borrowed = BigNumber.from(iUSDT_BORROWED);
          newAssetList[2].borrowed = BigNumber.from(iDAI_BORROWED);
          newAssetList[0].approvalToSickle = BigNumber.from(USDC_APPROVAL);
          newAssetList[1].approvalToSickle = BigNumber.from(USDT_APPROVAL);
          newAssetList[2].approvalToSickle = BigNumber.from(DAI_APPROVAL);
          newAssetList[0].supplierIndex = BigNumber.from(iUSDC_SUPPLIER_INDEX);
          newAssetList[1].supplierIndex = BigNumber.from(iUSDT_SUPPLIER_INDEX);
          newAssetList[2].supplierIndex = BigNumber.from(iDAI_SUPPLIER_INDEX);
          newAssetList[0].borrowerIndex = BigNumber.from(iUSDC_BORROWER_INDEX);
          newAssetList[1].borrowerIndex = BigNumber.from(iUSDT_BORROWER_INDEX);
          newAssetList[2].borrowerIndex = BigNumber.from(iDAI_BORROWER_INDEX);
        }

        // fetch DF price

        let fetchedDFprice = 0;
        let BSC_DAI_PRICE = 0;
        if(selectedNetwork === 'Optimism') {
          let velodromeRouterABI = new ethers.utils.Interface(
            JSON.stringify(ABIs.VELODROME.Router)
          );
          const VelodromeRouter = new ethers.Contract(
            ADDRESSES.Optimism.VELODROME?.Router ?? "",
            velodromeRouterABI,
            currentProvider?.getSigner()
          );
          const DFUSDCprice = await VelodromeRouter.getAmountsOut("1000000000000000000", [
            {
              from: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.DF,
              to: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.USX,
              stable: false,
            },
            {
              from: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.USX,
              to: ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.USDC,
              stable: true,
            },
          ]);
          fetchedDFprice = parseFloat(
            ethers.utils.formatEther(DFUSDCprice[1].mul(USDC_PRICE).div(1e8))
          );
          setDFprice(fetchedDFprice);
        } else if(selectedNetwork === 'Ethereum') {
          let uniswapV2RouterABI = new ethers.utils.Interface(
            JSON.stringify(ABIs.UNISWAP.V2Router)
          );
          const UniswapV2Router = new ethers.Contract(
            ADDRESSES.Ethereum.UNISWAP?.V2Router ?? "",
            uniswapV2RouterABI,
            currentProvider?.getSigner()
          );
          const DFUSDCprice = await UniswapV2Router.getAmountsOut(ethers.utils.parseEther("1"), [ADDRESSES.Ethereum.TOKENS?.DF, ADDRESSES.Ethereum.TOKENS?.WETH, ADDRESSES.Ethereum.TOKENS?.USDC]);
          fetchedDFprice = DFUSDCprice[2] * USDC_PRICE / 1e6 / 1e8;
          setDFprice(fetchedDFprice);
        } else if(selectedNetwork === 'Polygon') {
          let uniswapV3QuoterABI = new ethers.utils.Interface(
            JSON.stringify(ABIs.UNISWAP.V3Quoter)
          );
          const UniswapV3Quoter = new ethers.Contract(
            ADDRESSES.Polygon.UNISWAP?.V3Quoter ?? "",
            uniswapV3QuoterABI,
            currentProvider?.getSigner()
          );

          const DFUSDCprice = await UniswapV3Quoter.callStatic.quoteExactInputSingle(ADDRESSES.Polygon.TOKENS?.DF, ADDRESSES.Polygon.TOKENS?.USDC, 10000, ethers.utils.parseEther("1"), 0);
          fetchedDFprice = DFUSDCprice * USDC_PRICE / 1e6 / 1e8;
          setDFprice(fetchedDFprice);
        } else { // for BSC and Arbitrum we fetch the DF price from CoinGecko since there is no on-chain liquidity for the token
          const CoinGeckoClient = new CoinGecko();
          const fetchPriceData = async () => {
            let result;
            result = await CoinGeckoClient.simple.price({
                ids: ['dai', 'dforce-token'],
                vs_currencies: ['usd'],
            });

            fetchedDFprice = result.data[`dforce-token`].usd;
            setDFprice(fetchedDFprice);
            if(selectedNetwork === 'BSC') {
              BSC_DAI_PRICE = result.data[`dai`].usd;
            }
          }
          try {
            await fetchPriceData();
          } catch {
            /*
            window.alert('Failed to fetch Coingecko price data');
            return;
            */
           // failsafe for demo purposes only
           fetchedDFprice = 0.05;
           BSC_DAI_PRICE = 1.00;
          }
        }

        // fetch latest reference block number

        if(selectedNetwork === 'Optimism') {

          let IOVM_ABI = new ethers.utils.Interface(
            JSON.stringify(ABIs.Optimism.IOVM)
          );
          const IOVM = new ethers.Contract(
            ADDRESSES.Optimism?.IOVM ?? "",
            IOVM_ABI,
            currentProvider?.getSigner()
          );
          const L1_BLOCK_NUMBER = await IOVM.getL1BlockNumber();
          setReferenceBlockNumber(L1_BLOCK_NUMBER.toNumber());
        } else {
          const blockNumber = await currentProvider?.getBlockNumber();
          setReferenceBlockNumber(blockNumber);
        }

        // update asset list state

        let blocksPerYear: number;
        if(selectedNetwork === 'Polygon') {
          blocksPerYear = 39375 * 365; // yearly average
        } else if(selectedNetwork === 'BSC') {
          blocksPerYear = 28500 * 365; // yearly average
        } else {
          blocksPerYear = ((24 * 3600) / 12) * 365;
        } 

        let USDC_USDT_decimals : number;
        if(selectedNetwork === 'BSC') {
          USDC_USDT_decimals = 18;
        } else {
          USDC_USDT_decimals = 6;
        }

        newAssetList[0].price = USDC_PRICE / 1e8;
        newAssetList[1].price = USDT_PRICE / 1e8;
        newAssetList[2].price = selectedNetwork === 'BSC' ? BSC_DAI_PRICE : DAI_PRICE / 1e8;

        newAssetList[0].marketSize = (
          (parseFloat(
            BigNumber.from(iUSDC_CASH)
              .add(BigNumber.from(iUSDC_BORROWS))
              .toString()
          ) *
            (USDC_PRICE / 1e8)) /
          10 ** USDC_USDT_decimals
        ).toFixed(2);
        newAssetList[1].marketSize = (
          (parseFloat(
            BigNumber.from(iUSDT_CASH)
              .add(BigNumber.from(iUSDT_BORROWS))
              .toString()
          ) *
            (USDT_PRICE / 1e8)) /
          10 ** USDC_USDT_decimals
        ).toFixed(2);
        newAssetList[2].marketSize = (
          parseFloat(
            ethers.utils.formatEther(
              BigNumber.from(iDAI_CASH).add(BigNumber.from(iDAI_BORROWS))
            )
          ) *
          (newAssetList[2].price)
        ).toFixed(2);
        newAssetList[0].liquidity = (
          (parseFloat(BigNumber.from(iUSDC_CASH).toString()) *
            (USDC_PRICE / 1e8)) /
          10 ** USDC_USDT_decimals
        ).toFixed(2);
        newAssetList[1].liquidity = (
          (parseFloat(BigNumber.from(iUSDT_CASH).toString()) *
            (USDT_PRICE / 1e8)) /
          10 ** USDC_USDT_decimals
        ).toFixed(2);
        newAssetList[2].liquidity = (
          parseFloat(ethers.utils.formatEther(BigNumber.from(iDAI_CASH))) *
          (newAssetList[2].price)
        ).toFixed(2);
        newAssetList[0].LTV = (
          parseFloat(
            ethers.utils.formatEther(BigNumber.from(iUSDC_COLLATERAL_FACTOR))
          ) * 100
        ).toFixed(0);
        newAssetList[1].LTV = (
          parseFloat(
            ethers.utils.formatEther(BigNumber.from(iUSDT_COLLATERAL_FACTOR))
          ) * 100
        ).toFixed(0);
        newAssetList[2].LTV = (
          parseFloat(
            ethers.utils.formatEther(BigNumber.from(iDAI_COLLATERAL_FACTOR))
          ) * 100
        ).toFixed(0);
        newAssetList[0].supplyRate = (
          ((1 +
            parseFloat(
              ethers.utils.formatEther(BigNumber.from(iUSDC_SUPPLY_RATE))
            )) **
            blocksPerYear -
            1) *
          100
        ).toFixed(2);
        newAssetList[1].supplyRate = (
          ((1 +
            parseFloat(
              ethers.utils.formatEther(BigNumber.from(iUSDT_SUPPLY_RATE))
            )) **
            blocksPerYear -
            1) *
          100
        ).toFixed(2);
        newAssetList[2].supplyRate = (
          ((1 +
            parseFloat(
              ethers.utils.formatEther(BigNumber.from(iDAI_SUPPLY_RATE))
            )) **
            blocksPerYear -
            1) *
          100
        ).toFixed(2);
        newAssetList[0].borrowRate = (
          ((1 +
            parseFloat(
              ethers.utils.formatEther(BigNumber.from(iUSDC_BORROW_RATE))
            )) **
            blocksPerYear -
            1) *
          100
        ).toFixed(2);
        newAssetList[1].borrowRate = (
          ((1 +
            parseFloat(
              ethers.utils.formatEther(BigNumber.from(iUSDT_BORROW_RATE))
            )) **
            blocksPerYear -
            1) *
          100
        ).toFixed(2);
        newAssetList[2].borrowRate = (
          ((1 +
            parseFloat(
              ethers.utils.formatEther(BigNumber.from(iDAI_BORROW_RATE))
            )) **
            blocksPerYear -
            1) *
          100
        ).toFixed(2);
        newAssetList[0].supplyState = {
          block: parseInt(iUSDC_DF_SUPPLY_STATE_BLOCK_NUMBER),
          index: BigNumber.from(iUSDC_DF_SUPPLY_STATE_INDEX),
        };
        newAssetList[1].supplyState = {
          block: parseInt(iUSDT_DF_SUPPLY_STATE_BLOCK_NUMBER),
          index: BigNumber.from(iUSDT_DF_SUPPLY_STATE_INDEX),
        };
        newAssetList[2].supplyState = {
          block: parseInt(iDAI_DF_SUPPLY_STATE_BLOCK_NUMBER),
          index: BigNumber.from(iDAI_DF_SUPPLY_STATE_INDEX),
        };
        newAssetList[0].borrowState = {
          block: parseInt(iUSDC_DF_BORROW_STATE_BLOCK_NUMBER),
          index: BigNumber.from(iUSDC_DF_BORROW_STATE_INDEX),
        };
        newAssetList[1].borrowState = {
          block: parseInt(iUSDT_DF_BORROW_STATE_BLOCK_NUMBER),
          index: BigNumber.from(iUSDT_DF_BORROW_STATE_INDEX),
        };
        newAssetList[2].borrowState = {
          block: parseInt(iDAI_DF_BORROW_STATE_BLOCK_NUMBER),
          index: BigNumber.from(iDAI_DF_BORROW_STATE_INDEX),
        };
        newAssetList[0].supplyDFrewards = (
          (100 *
            (iUSDC_DF_SUPPLY_RATE / 1e18) *
            blocksPerYear *
            fetchedDFprice) /
          ((parseFloat(
            BigNumber.from(iUSDC_CASH)
              .add(BigNumber.from(iUSDC_BORROWS))
              .toString()
          ) *
            (USDC_PRICE / 1e8)) /
            10 ** USDC_USDT_decimals)
        ).toFixed(2);
        newAssetList[1].supplyDFrewards = (
          (100 *
            (iUSDT_DF_SUPPLY_RATE / 1e18) *
            blocksPerYear *
            fetchedDFprice) /
          ((parseFloat(
            BigNumber.from(iUSDT_CASH)
              .add(BigNumber.from(iUSDT_BORROWS))
              .toString()
          ) *
            (USDT_PRICE / 1e8)) /
            10 ** USDC_USDT_decimals)
        ).toFixed(2);
        newAssetList[2].supplyDFrewards = (
          (100 *
            (iDAI_DF_SUPPLY_RATE / 1e18) *
            blocksPerYear *
            fetchedDFprice) /
          (parseFloat(
            ethers.utils.formatEther(
              BigNumber.from(iDAI_CASH).add(BigNumber.from(iDAI_BORROWS))
            )
          ) *
            (newAssetList[2].price))
        ).toFixed(2);
        newAssetList[0].borrowDFrewards = (
          (100 *
            (iUSDC_DF_BORROW_RATE / 1e18) *
            blocksPerYear *
            fetchedDFprice) /
          ((parseFloat(BigNumber.from(iUSDC_BORROWS).toString()) *
            (USDC_PRICE / 1e8)) /
            10 ** USDC_USDT_decimals)
        ).toFixed(2);
        newAssetList[1].borrowDFrewards = (
          (100 *
            (iUSDT_DF_BORROW_RATE / 1e18) *
            blocksPerYear *
            fetchedDFprice) /
          ((parseFloat(BigNumber.from(iUSDT_BORROWS).toString()) *
            (USDT_PRICE / 1e8)) /
            10 ** USDC_USDT_decimals)
        ).toFixed(2);
        newAssetList[2].borrowDFrewards = (
          (100 *
            (iDAI_DF_BORROW_RATE / 1e18) *
            blocksPerYear *
            fetchedDFprice) /
          (parseFloat(
            ethers.utils.formatEther(BigNumber.from(iDAI_BORROWS))
          ) *
            (newAssetList[2].price))
        ).toFixed(2);
        newAssetList[0].totalBorrows = BigNumber.from(iUSDC_BORROWS);
        newAssetList[1].totalBorrows = BigNumber.from(iUSDT_BORROWS);
        newAssetList[2].totalBorrows = BigNumber.from(iDAI_BORROWS);
        newAssetList[0].totalSupply = BigNumber.from(iUSDC_TOTAL_SUPPLY);
        newAssetList[1].totalSupply = BigNumber.from(iUSDT_TOTAL_SUPPLY);
        newAssetList[2].totalSupply = BigNumber.from(iDAI_TOTAL_SUPPLY);
        newAssetList[0].borrowIndex = BigNumber.from(iUSDC_BORROW_INDEX);
        newAssetList[1].borrowIndex = BigNumber.from(iUSDT_BORROW_INDEX);
        newAssetList[2].borrowIndex = BigNumber.from(iDAI_BORROW_INDEX);
        newAssetList[0].currentBalance = BigNumber.from(USDC_BALANCE);
        newAssetList[1].currentBalance = BigNumber.from(USDT_BALANCE);
        newAssetList[2].currentBalance = BigNumber.from(DAI_BALANCE);
        newAssetList[0].supplySpeed = BigNumber.from(iUSDC_DF_SUPPLY_RATE);
        newAssetList[1].supplySpeed = BigNumber.from(iUSDT_DF_SUPPLY_RATE);
        newAssetList[2].supplySpeed = BigNumber.from(iDAI_DF_SUPPLY_RATE);
        newAssetList[0].borrowSpeed = BigNumber.from(iUSDC_DF_BORROW_RATE);
        newAssetList[1].borrowSpeed = BigNumber.from(iUSDT_DF_BORROW_RATE);
        newAssetList[2].borrowSpeed = BigNumber.from(iDAI_DF_BORROW_RATE);

        setAssetList(newAssetList);
      }
      setIsUpdatingData(false);
    };

    try {
      fetchBalancesAndContractData();
    } catch {
      window.alert("Failed to fetch balances and contract data");
      setIsUpdatingData(false);
    }

    return function cleanup() {
      setPendingDFrewards(BigNumber.from(0));
      setSickleAccountEquity("$0.00");
      setDFprice(0);
      setReferenceBlockNumber(0);
      setAssetList([
        {
          name: "USDC",
          price: 1.0, // in USD per Chainlink feeds
          marketSize: "", // nb of tokens
          liquidity: "", // nb of tokens
          LTV: "", // in %
          supplied: BigNumber.from(0), // nb of tokens
          borrowed: BigNumber.from(0), // nb of tokens
          supplyRate: "0.00", // in %
          borrowRate: "0.00", // in %
          supplyDFrewards: "0.00", // in %
          borrowDFrewards: "0.00", // in %
          currentBalance: BigNumber.from(0), // nb of tokens
          flashloanFee: 0.01, // in %
          approvalToSickle: BigNumber.from(0), // in nb of tokens
          supplyState: { block: 0, index: BigNumber.from(0) },
          borrowState: { block: 0, index: BigNumber.from(0) },
          totalBorrows: BigNumber.from(0),
          totalSupply: BigNumber.from(0),
          supplySpeed: BigNumber.from(0),
          borrowSpeed: BigNumber.from(0),
          supplierIndex: BigNumber.from(0),
          borrowerIndex: BigNumber.from(0),
          borrowIndex: BigNumber.from(0),
        },
        {
          name: "USDT",
          price: 1.0, // in USD per Chainlink feeds
          marketSize: "", // nb of tokens
          liquidity: "", // nb of tokens
          LTV: "", // in %
          supplied: BigNumber.from(0), // nb of tokens
          borrowed: BigNumber.from(0), // nb of tokens
          supplyRate: "0.00", // in %
          borrowRate: "0.00", // in %
          supplyDFrewards: "0.00", // in %
          borrowDFrewards: "0.00", // in %
          currentBalance: BigNumber.from(0), // nb of tokens
          flashloanFee: 0.01, // in %
          approvalToSickle: BigNumber.from(0), // in nb of tokens
          supplyState: { block: 0, index: BigNumber.from(0) },
          borrowState: { block: 0, index: BigNumber.from(0) },
          totalBorrows: BigNumber.from(0),
          totalSupply: BigNumber.from(0),
          supplySpeed: BigNumber.from(0),
          borrowSpeed: BigNumber.from(0),
          supplierIndex: BigNumber.from(0),
          borrowerIndex: BigNumber.from(0),
          borrowIndex: BigNumber.from(0),
        },
        {
          name: "DAI",
          price: 1.0, // in USD per Chainlink feeds
          marketSize: "", // nb of tokens
          liquidity: "", // nb of tokens
          LTV: "", // in %
          supplied: BigNumber.from(0), // nb of tokens
          borrowed: BigNumber.from(0), // nb of tokens
          supplyRate: "0.00", // in %
          borrowRate: "0.00", // in %
          supplyDFrewards: "0.00", // in %
          borrowDFrewards: "0.00", // in %
          currentBalance: BigNumber.from(0), // nb of tokens
          flashloanFee: 0.01, // in %
          approvalToSickle: BigNumber.from(0), // in nb of tokens
          supplyState: { block: 0, index: BigNumber.from(0) },
          borrowState: { block: 0, index: BigNumber.from(0) },
          totalBorrows: BigNumber.from(0),
          totalSupply: BigNumber.from(0),
          supplySpeed: BigNumber.from(0),
          borrowSpeed: BigNumber.from(0),
          supplierIndex: BigNumber.from(0),
          borrowerIndex: BigNumber.from(0),
          borrowIndex: BigNumber.from(0),
        },
      ]);
    };
  }, [txCounter]);

  const claimDFrewards = async () => {
    if (sickleAddress.length > 0 && !isDeployingSickle) {
      // claim DF reward tokens and sweep to user wallet
      let DForceStubABI = new ethers.utils.Interface(
        JSON.stringify(ABIs.STUBS.DForceStub)
      );
      let SickleABI = new ethers.utils.Interface(
        JSON.stringify(ABIs.STUBS.Sickle)
      );
      const DForceStub = new ethers.Contract(
        ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].STUBS?.DForceStub ?? "",
        DForceStubABI,
        currentProvider?.getSigner()
      );
      const Sickle = new ethers.Contract(
        sickleAddress,
        SickleABI,
        currentProvider?.getSigner()
      );

      const claimRewardsData = DForceStub.interface.encodeFunctionData(
        "claimDFTokenRewards",
        [
          ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.RewardDistributorV3,
          [sickleAddress],
          [
            ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDC,
            ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDT,
            ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iDAI,
          ],
          [
            ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDC,
            ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iUSDT,
            ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.iDAI,
          ],
        ]
      );
      const sweepDFTokenData = Sickle.interface.encodeFunctionData(
        "sweepTokens",
        [[ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.DF], currentAccount]
      );

      const gasEstimate = await Sickle.estimateGas.multicall(
        [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].STUBS?.DForceStub, sickleAddress],
        [claimRewardsData, sweepDFTokenData],
        [true, true],
        [0, 0]
      );

      await sendTransaction(
        Sickle.multicall(
          [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].STUBS?.DForceStub, sickleAddress],
          [claimRewardsData, sweepDFTokenData],
          [true, true],
          [0, 0],
          {
            gasLimit: gasEstimate.mul(110).div(100), // additional 10% margin on gas estimate
          }
        )
      );

      setTXcounter(txCounter+1);

    } else {
      window.alert("Sickle is not deployed !");
    }
  };

  const compoundDFrewards = async (
    token: string,
    supplied: BigNumber,
    borrowed: BigNumber
  ) => {
    if (sickleAddress.length > 0 && !isDeployingSickle) {
      let DForceStubABI = new ethers.utils.Interface(
        JSON.stringify(ABIs.STUBS.DForceStub)
      );
      let SickleABI = new ethers.utils.Interface(
        JSON.stringify(ABIs.STUBS.Sickle)
      );
      let VeloRouterABI = new ethers.utils.Interface(
        JSON.stringify(ABIs.VELODROME.Router)
      );
      let VelodromeSwapStubABI = new ethers.utils.Interface(
        JSON.stringify(ABIs.STUBS.VelodromeSwapStub)
      );
      const DForceStub = new ethers.Contract(
        ADDRESSES.Optimism.STUBS?.DForceStub ?? "",
        DForceStubABI,
        currentProvider?.getSigner()
      );
      const Sickle = new ethers.Contract(
        sickleAddress,
        SickleABI,
        currentProvider?.getSigner()
      );
      const VeloRouter = new ethers.Contract(
        ADDRESSES.Optimism.VELODROME?.Router ?? "",
        VeloRouterABI,
        currentProvider?.getSigner()
      );
      const VelodromeSwapStub = new ethers.Contract(
        ADDRESSES.Optimism.STUBS?.VelodromeSwapStub ?? "",
        VelodromeSwapStubABI,
        currentProvider?.getSigner()
      );

      const claimRewardsData = DForceStub.interface.encodeFunctionData(
        "claimDFTokenRewards",
        [
          ADDRESSES.Optimism.DFORCE?.RewardDistributorV3,
          [sickleAddress],
          [
            ADDRESSES.Optimism.DFORCE?.iUSDC,
            ADDRESSES.Optimism.DFORCE?.iUSDT,
            ADDRESSES.Optimism.DFORCE?.iDAI,
          ],
          [
            ADDRESSES.Optimism.DFORCE?.iUSDC,
            ADDRESSES.Optimism.DFORCE?.iUSDT,
            ADDRESSES.Optimism.DFORCE?.iDAI,
          ],
        ]
      );

      const approveRouterData = Sickle.interface.encodeFunctionData(
        "selfApprove",
        [
          ADDRESSES.Optimism.TOKENS?.DF,
          ADDRESSES.Optimism.VELODROME?.Router,
          ethers.constants.MaxUint256,
        ]
      );

      let swapRoutes;
      let DFstableAmounts;

      try {
        switch (token) {
          case "USDC":
            swapRoutes = [
              {
                from: ADDRESSES.Optimism.TOKENS?.DF,
                to: ADDRESSES.Optimism.TOKENS?.USX,
                stable: false,
              },
              {
                from: ADDRESSES.Optimism.TOKENS?.USX,
                to: ADDRESSES.Optimism.TOKENS?.USDC,
                stable: true,
              },
            ];
            DFstableAmounts = await VeloRouter.getAmountsOut(
              ethers.utils.parseEther("1"),
              swapRoutes
            );
            break;
          case "USDT":
            swapRoutes = [
              {
                from: ADDRESSES.Optimism.TOKENS?.DF,
                to: ADDRESSES.Optimism.TOKENS?.USX,
                stable: false,
              },
              {
                from: ADDRESSES.Optimism.TOKENS?.USX,
                to: ADDRESSES.Optimism.TOKENS?.USDC,
                stable: true,
              },
              {
                from: ADDRESSES.Optimism.TOKENS?.USDC,
                to: ADDRESSES.Optimism.TOKENS?.USDT,
                stable: true,
              },
            ];
            DFstableAmounts = await VeloRouter.getAmountsOut(
              ethers.utils.parseEther("1"),
              swapRoutes
            );
            break;
          case "DAI":
            swapRoutes = [
              {
                from: ADDRESSES.Optimism.TOKENS?.DF,
                to: ADDRESSES.Optimism.TOKENS?.USX,
                stable: false,
              },
              {
                from: ADDRESSES.Optimism.TOKENS?.USX,
                to: ADDRESSES.Optimism.TOKENS?.USDC,
                stable: true,
              },
              {
                from: ADDRESSES.Optimism.TOKENS?.USDC,
                to: ADDRESSES.Optimism.TOKENS?.DAI,
                stable: true,
              },
            ];
            DFstableAmounts = await VeloRouter.getAmountsOut(
              ethers.utils.parseEther("1"),
              swapRoutes
            );
            break;
        }
      } catch (err) {
        window.alert(`Failed to fetch Velo prices : ${err}`);
      }

      const DFstablePrice = DFstableAmounts[DFstableAmounts.length - 1];

      const swapRewardsData = VelodromeSwapStub.interface.encodeFunctionData(
        "swapTokens",
        [
          ADDRESSES.Optimism.VELODROME?.Router,
          swapRoutes,
          DFstablePrice,
          9900, // 9900 Inverted slippage ratio (in 10,000%), so 9900 means 1% slippage max
        ]
      );

      const approveiTokenData = Sickle.interface.encodeFunctionData(
        "selfApprove",
        [
          ADDRESSES.Optimism.TOKENS?.[token as keyof typeof ADDRESSES.Optimism.TOKENS],
          ADDRESSES.Optimism.DFORCE?.[`i${token}` as keyof typeof ADDRESSES.Optimism.DFORCE],
          ethers.constants.MaxUint256,
        ]
      );

      let uniswapV3PoolAssets: string[];
      if (token === "USDT") {
        uniswapV3PoolAssets = [ADDRESSES.Optimism.TOKENS?.USDC ?? "", ADDRESSES.Optimism.TOKENS?.USDT ?? ""];
      } else {
        uniswapV3PoolAssets = [ADDRESSES.Optimism.TOKENS?.USDC ?? "", ADDRESSES.Optimism.TOKENS?.DAI ?? ""];
      }

      const currentLeverageLevel = supplied
        .div("1000000000000000000")
        .mul(100)
        .div(supplied.div("1000000000000000000").sub(borrowed)); // maintaining the same level of leverage that the sickle currently has

      let flashloanMultipliers;
      if (token === "USDC") {
        flashloanMultipliers = [currentLeverageLevel, 0];
      } else {
        flashloanMultipliers = [0, currentLeverageLevel];
      }

      const leverageWithFlashloanData = DForceStub.interface.encodeFunctionData(
        "leverageWithFlashloan",
        [
          ethers.utils.defaultAbiCoder.encode(
            ["string", "uint24"],
            ["UniswapV3", 100]
          ),
          uniswapV3PoolAssets,
          flashloanMultipliers,
          ethers.utils.defaultAbiCoder.encode(
            [
              "address",
              "address",
              "address",
              "address",
              "address[]",
              "address",
              "bool",
            ],
            [
              ADDRESSES.Optimism.DFORCE?.[`i${token}` as keyof typeof ADDRESSES.Optimism.DFORCE],
              ADDRESSES.Optimism.TOKENS?.[token as keyof typeof ADDRESSES.Optimism.TOKENS],
              ADDRESSES.Optimism.DFORCE?.[`i${token}` as keyof typeof ADDRESSES.Optimism.DFORCE],
              "0x0000000000000000000000000000000000000000", // router address set to zero address because it will not be used
              [], // empty array because no swap will be executed
              ADDRESSES.Optimism.DFORCE?.Controller,
              false, // boolean indicating if the collateral asset is the wrapped version of the chain's native token
            ]
          ),
        ]
      );

      const gasEstimate = await Sickle.estimateGas.multicall(
        [
          ADDRESSES.Optimism.STUBS?.DForceStub,
          sickleAddress,
          ADDRESSES.Optimism.STUBS?.VelodromeSwapStub,
          sickleAddress,
          ADDRESSES.Optimism.STUBS?.DForceStub,
        ],
        [
          claimRewardsData,
          approveRouterData,
          swapRewardsData,
          approveiTokenData,
          leverageWithFlashloanData,
        ],
        [
          true,
          true,
          true,
          true,
          true,
        ],
        [
          0,
          0,
          0,
          0,
          0,
        ],
      );

      await sendTransaction(
        Sickle.multicall(
          [
            ADDRESSES.Optimism.STUBS?.DForceStub,
            sickleAddress,
            ADDRESSES.Optimism.STUBS?.VelodromeSwapStub,
            sickleAddress,
            ADDRESSES.Optimism.STUBS?.DForceStub,
          ],
          [
            claimRewardsData,
            approveRouterData,
            swapRewardsData,
            approveiTokenData,
            leverageWithFlashloanData,
          ],
          [true, true, true, true, true],
          [0, 0, 0, 0, 0],
          {
            gasLimit: gasEstimate.mul(110).div(100), // additional 10% margin on gas estimate
          }
        )
      );
      setTXcounter(txCounter+1);
    } else {
      window.alert("Sickle is not deployed !");
    }
  };

  const foldTokenAmountWithFlashloan = async (
    token: string,
    amount: BigNumber
  ) => {
    if (sickleAddress.length > 0 && !isDeployingSickle) {
      const networkAddressRegistry = ADDRESSES[selectedNetwork as keyof typeof ADDRESSES];
      let targetAssetArray = assetList.filter((el) => el.name === token);
      let res;
      // check if token allowance to sickle is sufficient, if not launch approval transaction
      if (
        targetAssetArray[0].currentBalance.gt(
          targetAssetArray[0].approvalToSickle
        )
      ) {
        const targetTokenContract = new ethers.Contract(
          networkAddressRegistry.TOKENS?.[token as keyof typeof networkAddressRegistry.TOKENS] ?? "",
          ABIs.DFORCE.iToken,
          currentProvider?.getSigner()
        );
        res = await sendTransaction(
          targetTokenContract.approve(
            sickleAddress,
            targetAssetArray[0].currentBalance
          )
        ); // full balance is used to create a position
      }

      if (
        res ||
        targetAssetArray[0].currentBalance.lte(
          targetAssetArray[0].approvalToSickle
        )
      ) {
        // approve iToken contract to use Sickle's token balance and leverage with flashloan
        let DForceStubABI = new ethers.utils.Interface(ABIs.STUBS.DForceStub);
        let SickleABI = new ethers.utils.Interface(
          JSON.stringify(ABIs.STUBS.Sickle)
        );
        const DForceStub = new ethers.Contract(
          ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].STUBS?.DForceStub ?? "",
          DForceStubABI,
          currentProvider?.getSigner()
        );
        const Sickle = new ethers.Contract(
          sickleAddress,
          SickleABI,
          currentProvider?.getSigner()
        );
        const tokenContract = new ethers.Contract(
          networkAddressRegistry.TOKENS?.[token as keyof typeof networkAddressRegistry.TOKENS] ?? "",
          ABIs.DFORCE.iToken,
          currentProvider?.getSigner()
        );

        const transferTokentoSickleData =
          tokenContract.interface.encodeFunctionData("transferFrom", [
            currentAccount,
            sickleAddress,
            targetAssetArray[0].currentBalance,
          ]);

        const approveItokenData = Sickle.interface.encodeFunctionData(
          "selfApprove",
          [
            networkAddressRegistry.TOKENS?.[token as keyof typeof networkAddressRegistry.TOKENS],
            networkAddressRegistry.DFORCE?.[`i${token}` as keyof typeof networkAddressRegistry.DFORCE],
            amount,
          ]
        );

        let uniswapV3PoolAssets: string[];
        if (token === "USDT") {
          uniswapV3PoolAssets = [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.USDC ?? "", ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.USDT ?? ""];
        } else {
          uniswapV3PoolAssets = [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.USDC ?? "", ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.DAI ?? ""];
        }

        let flashloanMultipliers;
        if (token === "USDC") {
          flashloanMultipliers = [
            amount.mul(100).div(targetAssetArray[0].currentBalance),
            0,
          ];
        } else {
          flashloanMultipliers = [
            0,
            amount.mul(100).div(targetAssetArray[0].currentBalance),
          ];
        }

        const leverageWithFlashloanData =
          DForceStub.interface.encodeFunctionData("leverageWithFlashloan", [
            ethers.utils.defaultAbiCoder.encode(
              ["string", "uint24"],
              ["UniswapV3", 100]
            ),
            uniswapV3PoolAssets,
            flashloanMultipliers,
            ethers.utils.defaultAbiCoder.encode(
              [
                "address",
                "address",
                "address",
                "address",
                "address[]",
                "address",
                "bool",
              ],
              [
                networkAddressRegistry.DFORCE?.[`i${token}` as keyof typeof networkAddressRegistry.DFORCE],
                networkAddressRegistry.TOKENS?.[token as keyof typeof networkAddressRegistry.TOKENS],
                networkAddressRegistry.DFORCE?.[`i${token}` as keyof typeof networkAddressRegistry.DFORCE],
                "0x0000000000000000000000000000000000000000", // router address set to zero address because it will not be used
                [], // empty array because no swap will be executed
                ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.Controller,
                false, // boolean indicating if the collateral asset is the wrapped version of the chain's native token
              ]
            ),
          ]);

          const gasEstimate = await Sickle.estimateGas.multicall(
            [networkAddressRegistry.TOKENS?.[token as keyof typeof networkAddressRegistry.TOKENS], sickleAddress, DForceStub.address],
            [transferTokentoSickleData, approveItokenData, leverageWithFlashloanData],
            [false, true, true],
            [0, 0, 0],
          );

        await sendTransaction(
          Sickle.multicall(
            [
              networkAddressRegistry.TOKENS?.[token as keyof typeof networkAddressRegistry.TOKENS],
              sickleAddress,
              DForceStub.address,
            ],
            [
              transferTokentoSickleData,
              approveItokenData,
              leverageWithFlashloanData,
            ],
            [false, true, true],
            [0, 0, 0],
            {
              gasLimit: gasEstimate.mul(110).div(100), // additional 10% margin on gas estimate
            }
          )
        );
      }
      setTXcounter(txCounter+1);
    } else {
      window.alert("Sickle is not deployed !");
    }
  };

  const closeTokenAmountWithFlashloan = async (
    token: string,
    amount: BigNumber,
    isExiting: boolean
  ) => {
    if (sickleAddress.length > 0 && !isDeployingSickle) {
      const networkAddressRegistry = ADDRESSES[selectedNetwork as keyof typeof ADDRESSES];
      let DForceStubABI = new ethers.utils.Interface(ABIs.STUBS.DForceStub);
      let SickleABI = new ethers.utils.Interface(
        JSON.stringify(ABIs.STUBS.Sickle)
      );
      const DForceStub = new ethers.Contract(
        ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].STUBS?.DForceStub ?? "",
        DForceStubABI,
        currentProvider?.getSigner()
      );
      const Sickle = new ethers.Contract(
        sickleAddress,
        SickleABI,
        currentProvider?.getSigner()
      );

      let uniswapV3PoolAssets: string[];
      if (token === "USDT") {
        uniswapV3PoolAssets = [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.USDC ?? "", ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.USDT ?? ""];
      } else {
        uniswapV3PoolAssets = [ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.USDC ?? "", ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].TOKENS?.DAI ?? ""];
      }

      let flashloanAmounts; // N.B : due to rounding errors in iToken conversions, some dust may remain in the lending protocol
      if (token === "USDC") {
        flashloanAmounts = [amount, 0];
      } else {
        flashloanAmounts = [0, amount];
      }

      const approveItokenData = Sickle.interface.encodeFunctionData(
        "selfApprove",
        [
          networkAddressRegistry.TOKENS?.[token as keyof typeof networkAddressRegistry.TOKENS],
          networkAddressRegistry.DFORCE?.[`i${token}` as keyof typeof networkAddressRegistry.DFORCE],
          amount,
        ]
      );

      const repayPositionWithFlashloanData =
        DForceStub.interface.encodeFunctionData("repayPositionWithFlashloan", [
          ethers.utils.defaultAbiCoder.encode(
            ["string", "uint24"],
            ["UniswapV3", 100]
          ),
          uniswapV3PoolAssets,
          flashloanAmounts,
          ethers.utils.defaultAbiCoder.encode(
            [
              "address",
              "address",
              "address",
              "address",
              "address[]",
              "address",
              "bool",
            ],
            [
              networkAddressRegistry.DFORCE?.[`i${token}` as keyof typeof networkAddressRegistry.DFORCE],
              networkAddressRegistry.TOKENS?.[token as keyof typeof networkAddressRegistry.TOKENS],
              networkAddressRegistry.DFORCE?.[`i${token}` as keyof typeof networkAddressRegistry.DFORCE],
              "0x0000000000000000000000000000000000000000", // router address set to zero address because it will not be used
              [], // empty array because no swap will be executed
              ADDRESSES[selectedNetwork as keyof typeof ADDRESSES].DFORCE?.Controller,
              isExiting,
            ]
          ),
        ]);

      const sweepTokenData = Sickle.interface.encodeFunctionData(
        "sweepTokens",
        [
          [networkAddressRegistry.TOKENS?.[token as keyof typeof networkAddressRegistry.TOKENS]],
          currentAccount,
        ]
      );

      const gasEstimate = await Sickle.estimateGas.multicall(
        [sickleAddress, DForceStub.address, sickleAddress],
        [approveItokenData, repayPositionWithFlashloanData, sweepTokenData],
        [true, true, true],
        [0, 0, 0],
      );

      await sendTransaction(
        Sickle.multicall(
          [sickleAddress, DForceStub.address, sickleAddress],
          [approveItokenData, repayPositionWithFlashloanData, sweepTokenData],
          [true, true, true],
          [0, 0, 0],
          {
            gasLimit: gasEstimate.mul(110).div(100), // additional 10% margin on gas estimate
          }
        )
      );

      setTXcounter(txCounter+1);
    } else {
      window.alert("Sickle is not deployed !");
    }
  };

  return (
    <div>
      <div style={{ marginTop: "20px" }}>
        ----------------------------------------------------------------------------
      </div>
      <div style={{ marginTop: "20px", fontWeight: "bold" }}>
        DForce Network on {selectedNetwork}
      </div>
      <div style={{ marginTop: "30px" }}>
        Sickle Account Equity on DForce : {sickleAccountEquity}
      </div>
      <div style={{ marginTop: "20px" }}>
        DF token price :{" "}
        {DFprice.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </div>
      {assetList.map(
        ({
          name,
          price,
          marketSize,
          liquidity,
          LTV,
          supplied,
          borrowed,
          supplyRate,
          borrowRate,
          supplyDFrewards,
          borrowDFrewards,
          currentBalance,
          flashloanFee,
          supplyState,
          borrowState,
          supplySpeed,
          borrowSpeed,
          totalSupply,
          totalBorrows,
          supplierIndex,
          borrowerIndex,
          borrowIndex,
        }) => (
          <StrategyData
            key={name}
            name={name}
            price={price}
            marketSize={marketSize}
            liquidity={liquidity}
            LTV={LTV}
            supplied={supplied}
            borrowed={borrowed}
            supplyRate={supplyRate}
            borrowRate={borrowRate}
            supplyDFrewards={supplyDFrewards}
            borrowDFrewards={borrowDFrewards}
            currentBalance={currentBalance}
            flashloanFee={flashloanFee}
            pendingDFrewards={pendingDFrewards}
            supplyState={supplyState}
            borrowState={borrowState}
            supplySpeed={supplySpeed}
            borrowSpeed={borrowSpeed}
            totalSupply={totalSupply}
            totalBorrows={totalBorrows}
            supplierIndex={supplierIndex}
            borrowerIndex={borrowerIndex}
            referenceBlockNumber={referenceBlockNumber}
            DFprice={DFprice}
            borrowIndex={borrowIndex}
            claimDFrewards={claimDFrewards}
            compoundDFrewards={compoundDFrewards}
            foldTokenAmountWithFlashloan={foldTokenAmountWithFlashloan}
            closeTokenAmountWithFlashloan={closeTokenAmountWithFlashloan}
            selectedNetwork={selectedNetwork}
          />
        )
      )}
    </div>
  );
};

export default DForceData;
