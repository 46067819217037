const OPTIMISM = {
    "chainId": "0xA",
    "chainName": "Optimistic Ethereum",
    "nativeCurrency": {
      "name": "ethereum",
      "symbol": "OETH",
      "decimals": 18
    },
    "rpcUrls": [
      "https://mainnet.optimism.io/"
    ],
    "blockExplorerUrls": [
      "https://optimistic.etherscan.io/"
    ]
  }  

  export const chainIDregistry = {
    "Ethereum": {
      chainID : 1,
      chainIDhex: '0x1',
      name: "Ethereum Mainnet",
      rpcUrls: ["https://api.mycryptoapi.com/eth"],
      blockExplorerUrls: ["https://etherscan.io"],
    },
    "Arbitrum": {
      chainID : 42161,
      chainIDhex: '0xa4b1',
      name: "Arbitrum One",
      rpcUrls: ["https://rpc.ankr.com/arbitrum"],
      blockExplorerUrls: ["https://arbiscan.io/"],
    },
    "Optimism": {
      chainID: 10,
      chainIDhex: '0xa',
      name: "Optimism",
      rpcUrls: ["https://mainnet.optimism.io/"],
      blockExplorerUrls: ["https://optimistic.etherscan.io"],
    },
    "BSC": {
      chainID: 56,
      chainIDhex: '0x38',
      name: "Binance Smart Chain Mainnet",
      rpcUrls: ["https://bsc-dataseed.binance.org"],
      blockExplorerUrls: ["https://bscscan.com/"],
    },
    "Polygon": {
      chainID: 137,
      chainIDhex: '0x89',
      name: "Polygon Mainnet",
      rpcUrls: ["https://polygon-rpc.com"],
      blockExplorerUrls: ["https://polygonscan.com/"],
    },
    "Base": {
      chainID: 8453,
      chainIDhex: '0x2105',
      name: "Base",
      rpcUrls: ["https://mainnet.base.org"],
      blockExplorerUrls: ["https://basescan.org"],
    },
  }