import { BigNumber, ethers } from "ethers";
import { ADDRESSES, ABIs } from "../../ContractInfo";
import { ContractCallContext, ContractCallResults, Multicall } from "ethereum-multicall";
import { Route, Routes, ZapInData } from "./AeroInterfaces";

export const contractCallContext1 = (token0: string, token1: string): ContractCallContext[] => {
    return [
        {
          reference: "PoolFactory",
          contractAddress: ADDRESSES.Base.AERODROME?.PoolFactory ?? "",
          abi: ABIs.AERODROME.PoolFactory,
          calls: [
            {
              reference: "pool0stable",
              methodName: "getPool(address,address,bool)",
              methodParameters: [token0, ADDRESSES.Base.TOKENS?.USDbC, true],
            },
            {
              reference: "pool0volatile",
              methodName: "getPool(address,address,bool)",
              methodParameters: [token0, ADDRESSES.Base.TOKENS?.USDbC, false],
            },
            {
              reference: "pool1stable",
              methodName: "getPool(address,address,bool)",
              methodParameters: [token1, ADDRESSES.Base.TOKENS?.USDbC, true],
            },
            {
              reference: "pool1volatile",
              methodName: "getPool(address,address,bool)",
              methodParameters: [token1, ADDRESSES.Base.TOKENS?.USDbC, false],
            },
          ],
        },
      ];
} 

export const contractCallContext2 = (pool0stableAddress: string, pool1stableAddress: string, pool0volatileAddress: string, pool1volatileAddress: string): ContractCallContext[] =>
{
    return [
                {
                  reference: "USDbC",
                  contractAddress: ADDRESSES.Base.TOKENS?.USDbC ?? "",
                  abi: ABIs.AERODROME.Gauge,
                  calls: [
                    {
                      reference: "pool0stableUSDbC",
                      methodName: "balanceOf",
                      methodParameters: [pool0stableAddress],
                    },
                    {
                      reference: "pool0volatileUSDbC",
                      methodName: "balanceOf",
                      methodParameters: [pool0volatileAddress],
                    },
                    {
                      reference: "pool1stableUSDbC",
                      methodName: "balanceOf",
                      methodParameters: [pool1stableAddress],
                    },
                    {
                      reference: "pool1volatileUSDbC",
                      methodName: "balanceOf",
                      methodParameters: [pool1volatileAddress],
                    },
                  ],
                },
            ]
};

export const getPoolRoutes = (routes: Routes, token0: string, token1: string, isStable: boolean,
  pool0stableUSDbC: any, pool0volatileUSDbC: any, pool0stableAddress: string, pool0volatileAddress: string, 
  pool1stableUSDbC: any, pool1volatileUSDbC: any, pool1stableAddress: string, pool1volatileAddress:  string) => {
    const maxUSDbCbalanceInPool = Math.max(parseInt(pool0stableUSDbC.toString()), parseInt(pool0volatileUSDbC.toString()), parseInt(pool1stableUSDbC.toString()), parseInt(pool1volatileUSDbC.toString()));

    if(parseInt(pool0stableUSDbC.toString()) === maxUSDbCbalanceInPool && pool0stableAddress !== "0x0000000000000000000000000000000000000000") {
      routes.routeToIntermediate[1] = {
        from: ADDRESSES.Base.TOKENS?.USDbC ?? "",
        to: token0,
        stable: true,
        factory: ADDRESSES.Base.AERODROME?.PoolFactory ?? ""
      };
        routes.routeToOther[0] = {
        from: token0,
        to: token1,
        stable: isStable,
        factory: ADDRESSES.Base.AERODROME?.PoolFactory ?? ""
      };
    } else if (parseInt(pool0volatileUSDbC.toString()) === maxUSDbCbalanceInPool && pool0volatileAddress !== "0x0000000000000000000000000000000000000000") {
      routes.routeToIntermediate[1] = {
        from: ADDRESSES.Base.TOKENS?.USDbC ?? "",
        to: token0,
        stable: false,
        factory: ADDRESSES.Base.AERODROME?.PoolFactory ?? ""
      };
      routes.routeToOther[0] = {
        from: token0,
        to: token1,
        stable: isStable,
        factory: ADDRESSES.Base.AERODROME?.PoolFactory ?? ""
    };
    } else if (parseInt(pool1stableUSDbC.toString()) === maxUSDbCbalanceInPool && pool1stableAddress !== "0x0000000000000000000000000000000000000000") {
      routes.routeToIntermediate[1] = {
        from: ADDRESSES.Base.TOKENS?.USDbC ?? "",
        to: token1,
        stable: true,
        factory: ADDRESSES.Base.AERODROME?.PoolFactory ?? ""
      };
      routes.routeToOther[0] = {
        from: token1,
        to: token0,
        stable: isStable,
        factory: ADDRESSES.Base.AERODROME?.PoolFactory ?? ""
      };
    } else if (parseInt(pool1volatileUSDbC.toString()) === maxUSDbCbalanceInPool && pool1volatileAddress !== "0x0000000000000000000000000000000000000000") {
      routes.routeToIntermediate[1] = {
        from: ADDRESSES.Base.TOKENS?.USDbC ?? "",
        to: token1,
        stable: false,
        factory: ADDRESSES.Base.AERODROME?.PoolFactory ?? ""
      };
      routes.routeToOther[0] = {
        from: token1,
        to: token0,
        stable: isStable,
        factory: ADDRESSES.Base.AERODROME?.PoolFactory ?? ""
      };
    } else if(maxUSDbCbalanceInPool === 0) {
    return {
      routes,
      error: true,
      errorMessage: "Could not find path to swap AERO rewards into LP tokens"
    }
}

  return {
    error: false,
    errorMessage: "",
    routes
  }
}

export const getRoutes = async (token0: string, token1: string, isStable: boolean, 
  currentProvider: ethers.providers.Web3Provider | undefined) => {
      let result: {
        error: Boolean,
        errorMessage: String,
        routes: Routes
      } = {error: false, 
           errorMessage: "", 
           routes: {routeToIntermediate: [], routeToOther: []}}
      
      if(token0 === ADDRESSES.Base.TOKENS?.AERO || token1 === ADDRESSES.Base.TOKENS?.AERO) {
        // routeToIntermediate remains empty
        result.routes.routeToOther[0] = {
          from: ADDRESSES.Base.TOKENS?.AERO ?? "",
          to: token0 === ADDRESSES.Base.TOKENS?.AERO ? token1 : token0,
          stable: isStable,
          factory: ADDRESSES.Base.AERODROME?.PoolFactory ?? ""
        }
      } else if (token0 === ADDRESSES.Base.TOKENS?.WETH || token1 === ADDRESSES.Base.TOKENS?.WETH) {
        result.routes.routeToIntermediate[0] = {
          from: ADDRESSES.Base.TOKENS?.AERO ?? "",
          to: ADDRESSES.Base.TOKENS?.WETH ?? "",
          stable: false,
          factory: ADDRESSES.Base.AERODROME?.PoolFactory ?? ""
        }
        result.routes.routeToOther[0] = {
          from: ADDRESSES.Base.TOKENS?.WETH ?? "",
          to: token0 === ADDRESSES.Base.TOKENS?.WETH ? token1 : token0,
          stable: isStable,
          factory: ADDRESSES.Base.AERODROME?.PoolFactory ?? ""
        }
      } else if (token0 === ADDRESSES.Base.TOKENS?.USDbC || token1 === ADDRESSES.Base.TOKENS?.USDbC) {
        result.routes.routeToIntermediate[0] = {
          from: ADDRESSES.Base.TOKENS?.AERO ?? "",
          to: ADDRESSES.Base.TOKENS?.USDbC ?? "",
          stable: false,
          factory: ADDRESSES.Base.AERODROME?.PoolFactory ?? ""
        }
        result.routes.routeToOther[0] = {
          from: ADDRESSES.Base.TOKENS?.USDbC ?? "",
          to: token0 === ADDRESSES.Base.TOKENS?.USDbC ? token1 : token0,
          stable: isStable,
          factory: ADDRESSES.Base.AERODROME?.PoolFactory ?? ""
        }
      } else {
        result.routes.routeToIntermediate[0] = {
          from: ADDRESSES.Base.TOKENS?.AERO ?? "",
          to: ADDRESSES.Base.TOKENS?.USDbC ?? "",
          stable: false,
          factory: ADDRESSES.Base.AERODROME?.PoolFactory ?? ""
        }
    
        // fetching stable and volatile pools linking token0 and token1 to USDbC
        // fetching USDbC balance for each pool to assess pool with most liquidity
        // defining route to swap AERO rewards best on pool with best liquidity
        // List of current pools requiring a bridge swap through USDBc : 
        /* 
        - ERN / stERN --> stERN/USD+ --> USD+/USDbC
        - DOLA / MAI --> DOLA/USDbC
        - DAI+ / USD+ --> USD+/USDbC
        - USD+ / stERN --> USD+/USDbC
        - YFX / USD+ --> USD+/USDbC ou YFX/USDbC
        - DOLA / USD+ --> USD+/USDbC ou DOLA/USDbC
        - OVN / USD+ --> USD+/USDbC
        - tBTC / T --> tBTC/USDbC
        - oBMX / USDC --> USDC/USDbC
        */
    
        if(currentProvider) {
          const multicall = new Multicall({
            ethersProvider: currentProvider,
            tryAggregate: true,
          });
    
          
    
          const results1: ContractCallResults = await multicall.call(
            contractCallContext1(token0, token1)
          );
    
          const {
            results: {
              PoolFactory: {
                callsReturnContext: {
                  0: {
                    returnValues: {
                      0: pool0stableAddress,
                    },
                  },
                },
              },
            },
            results: {
              PoolFactory: {
                callsReturnContext: {
                  1: {
                    returnValues: {
                      0: pool0volatileAddress,
                    },
                  },
                },
              },
            },
            results: {
              PoolFactory: {
                callsReturnContext: {
                  2: {
                    returnValues: {
                      0: pool1stableAddress,
                    },
                  },
                },
              },
            },
            results: {
              PoolFactory: {
                callsReturnContext: {
                  3: {
                    returnValues: {
                      0: pool1volatileAddress,
                    },
                  },
                },
              },
            },
          } = results1;
    
          const results2: ContractCallResults = await multicall.call(
            contractCallContext2(pool0stableAddress, pool1stableAddress, pool0volatileAddress, pool1volatileAddress)
          );
    
          const {
            results: {
              USDbC: {
                callsReturnContext: {
                  0: {
                    returnValues: {
                      0: {hex: pool0stableUSDbC},
                    },
                  },
                },
              },
            },
            results: {
              USDbC: {
                callsReturnContext: {
                  1: {
                    returnValues: {
                      0: {hex: pool0volatileUSDbC},
                    },
                  },
                },
              },
            },
            results: {
              USDbC: {
                callsReturnContext: {
                  2: {
                    returnValues: {
                      0: {hex: pool1stableUSDbC},
                    },
                  },
                },
              },
            },
            results: {
              USDbC: {
                callsReturnContext: {
                  3: {
                    returnValues: {
                      0: {hex: pool1volatileUSDbC},
                    },
                  },
                },
              },
            },
          } = results2;

          const poolRoutes = getPoolRoutes(result.routes, token0, token1, isStable,
            pool0stableUSDbC, pool0volatileUSDbC, pool0stableAddress, pool0volatileAddress,
            pool1stableUSDbC, pool1volatileUSDbC, pool1stableAddress, pool1volatileAddress)

          result = {
              error: poolRoutes.error,
              errorMessage: poolRoutes.errorMessage,
              routes: poolRoutes.routes
        }
      }
    }

    return result
  }



export const generateZapInData = async (currentProvider: ethers.providers.Web3Provider | undefined, 
    poolAddress: string, pendingRewardBalance: BigNumber, isStable: boolean, token0: string, token1: string) => {
  
      let zapInData: ZapInData = {
        router: ADDRESSES.Base.AERODROME?.Router ?? "",
        tokenIn: ADDRESSES.Base.TOKENS?.AERO ?? "",
        amountIn : pendingRewardBalance,
        routeToIntermediate: [],
        intermediateMinAmountOut: BigNumber.from(0),
        routeToOther: [],
        otherMinAmountOut: 0,
        lpToken: poolAddress,
        lpTokenMinAmountOut : 0,
        isStablePool: isStable
      }
    
      const routes = await getRoutes(token0, token1, isStable, currentProvider)

      zapInData.routeToIntermediate = routes ? routes.routes.routeToIntermediate : []
      zapInData.routeToOther = routes ? routes.routes.routeToOther : []
      
      return {
        error: routes?.error,
        errorMessage: routes?.errorMessage,
        zapInData
      }
    }
