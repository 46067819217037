import {useState} from 'react';

import Links from './Links';
import Header from './Header';
import HomeTable from './HomeTable';
import ProtocolsHead from './Protocols/ProtocolsHead';

declare global {
  interface Window {
      ethereum?: import('ethers').providers.ExternalProvider;
  }
}

function App() {

  // UI data
  const [selectedProtocol, setSelectedProtocol] = useState<string>("");
  const [selectedNetwork, setSelectedNetwork] = useState<string>("");
  
  // functions

  const displayProtocolData = (protocol: string, network: string) => {
      setSelectedProtocol(protocol);
      setSelectedNetwork(network);
  }

  return (
    <div className="App">
      <Links
        displayProtocolData={displayProtocolData}              />
      <Header/>
      <div>
        {selectedProtocol === "" ? 
        <HomeTable
        displayProtocolData={displayProtocolData}
        /> : 
        <ProtocolsHead
          selectedProtocol={selectedProtocol}
          selectedNetwork={selectedNetwork}
        />
        }
      </div>
    </div>
  );
}

export default App;
