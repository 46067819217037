import {FC} from 'react';

type LinksProps = {
    displayProtocolData: (protocol: string, network: string) => void;
}

const Links: FC<LinksProps> = ({
    displayProtocolData
}) => {

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <a href="#" onClick={() => displayProtocolData("", "")}>Home</a>
        </div>
    )
};

export default Links;